import { useEffect } from "react";
import { login, logout } from "../redux/slices/auth";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import Cookies from "js-cookie";

export const useCheckAuth = () => {
    const { loginInfo, status } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!Cookies.get("auth2")) {
            if (
                !(
                    loginInfo.id &&
                    loginInfo.email &&
                    loginInfo.image_name &&
                    loginInfo.image_url &&
                    loginInfo.token
                )
            ) {
                dispatch(logout({ errorMessage: "" }));
            } else {
                Cookies.set(
                    "auth2",
                    JSON.stringify({
                        status: status,
                        id: loginInfo.id,
                        email: loginInfo.email,
                        image_name: loginInfo.image_name,
                        image_url: loginInfo.image_url,
                        client_id: loginInfo.client_id,
                        token: loginInfo.token,
                        fleetAdmin: loginInfo.fleetAdmin,
                        fuelAdmin: loginInfo.fuelAdmin,
                        superAdmin: loginInfo.superAdmin,
                        hash_employee: loginInfo.hash_employee,
                        validation_status: loginInfo.validation_status,
                    })
                );
                dispatch(
                    login({
                        loginInfo: {
                            id: loginInfo.id,
                            email: loginInfo.email,
                            image_name: loginInfo.image_name,
                            image_url: loginInfo.image_url,
                            client_id: loginInfo.client_id,
                            user_groups: loginInfo.user_groups,
                            token: loginInfo.token,
                            fleetAdmin: loginInfo.fleetAdmin,
                            fuelAdmin: loginInfo.fuelAdmin,
                            superAdmin: loginInfo.superAdmin,
                            hash_employee: loginInfo.hash_employee,
                            validation_status: loginInfo.validation_status,
                        },
                    })
                );
            }
        } else {
            const auth = JSON.parse(Cookies.get("auth2") as string);
            dispatch(
                login({
                    loginInfo: {
                        id: auth.id,
                        email: auth.email,
                        image_name: auth.image_name,
                        image_url: auth.image_url,
                        client_id: auth.client_id,
                        user_groups: auth.user_groups,
                        token: auth.token,
                        fleetAdmin: loginInfo.fleetAdmin,
                        fuelAdmin: loginInfo.fuelAdmin,
                        superAdmin: loginInfo.superAdmin,
                        hash_employee: loginInfo.hash_employee,
                        validation_status: loginInfo.validation_status,
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return status;
};
