import { useAppSelector } from "../redux/hooks";
import { Navigate } from "react-router-dom";

const PrivateRouteWrapper = ({
    children,
    allowedGroups,
    navigateTo,
}: {
    children: JSX.Element;
    allowedGroups: string[]; //["fleet","admin"] or ["fleet_employee"]
    navigateTo: string;
}) => {
    const { loginInfo } = useAppSelector((state) => state.auth);
    let isAllowed = loginInfo.user_groups?.includes("admin");

    if (loginInfo.user_groups && !isAllowed) {
        for (let i = 0; i < loginInfo.user_groups?.length; i++) {
            isAllowed = allowedGroups.includes(loginInfo.user_groups[i]);
            if (isAllowed) break;
        }
    }

    return isAllowed ? children : <Navigate to={navigateTo} replace />;
};

export default PrivateRouteWrapper;
