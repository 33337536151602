import { useEffect } from "react";
import {
    clearSelectedProductPlatform,
    setSelectedProductPlatform,
} from "../../redux/slices/auth";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useCookie } from "../cookie/useCookie";

export const useSelectPlatformProduct = () => {
    const { selectedProductPlatform } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const { setValue, storedValue } = useCookie("selectedProduct", "");

    useEffect(() => {
        if (!storedValue) {
            if (!selectedProductPlatform) {
                dispatch(clearSelectedProductPlatform());
            } else {
                setValue(selectedProductPlatform);
                dispatch(
                    setSelectedProductPlatform({
                        selectedProductPlatform: selectedProductPlatform,
                    })
                );
            }
        } else {
            dispatch(
                setSelectedProductPlatform({
                    selectedProductPlatform: storedValue,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
