import { Input } from "@doar/components";
import image from "@doar/shared/images/img19.png";
import {
    StyledWrap,
    StyledImg,
    StyledTitle,
    StyledSubTitle,
    StyledDesc,
} from "./style";

const ErrorContainer = () => {
    return (
        <StyledWrap>
            <StyledImg>
                <img src={image} alt="404" />
            </StyledImg>
            <StyledTitle>404 Página No Encontrada</StyledTitle>
            <StyledSubTitle>
                Oopps. Parece que la página a la que estás accediendo no existe.
            </StyledSubTitle>
            <StyledDesc>
                Tal vez te equivocaste en la ruta o fue movida de lugar. Prueba
                regresar al inicio.
            </StyledDesc>
        </StyledWrap>
    );
};

export default ErrorContainer;
