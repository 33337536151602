import { useState } from "react";
import { AxiosResponse } from "axios";
import { cityGasAPI } from "../../apis";
import {
    IRFCValidated,
    IRFCValidationResponse,
} from "../../interfaces/rfc-validation/rfcValidationResponse";

export const useRFCValidation = () => {
    const [rfcValidated, setRFCValidated] = useState<IRFCValidated>(
        {} as IRFCValidated
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    const validateRFC = async ({
        rfc,
        token,
    }: {
        rfc: string;
        token: string;
    }) => {
        setLoading(true);
        try {
            const response: AxiosResponse<IRFCValidationResponse> =
                await cityGasAPI.get("/users/consult_rfc", {
                    params: {
                        rfc,
                    },
                    headers: {
                        "X-API-KEY": token,
                    },
                });
            setRFCValidated(response.data.response);
        } catch (err) {
            setError(err);
            console.log(err);
        }
        setLoading(false);
    };

    return {
        validateRFC,
        rfcValidated,
        loading,
        error,
    };
};
