import {
    Calendar,
    MessageSquare,
    Users,
    FileText,
    Mail,
    LogIn,
    UserPlus,
    UserCheck,
    ShieldOff,
    User,
    File,
    PieChart,
    Package,
    Layers,
    Box,
    Archive,
    AlertCircle,
    ShoppingBag,
    Globe,
    LifeBuoy,
    ShoppingCart,
} from "react-feather";

const asideMenus = [
    {
        id: 1,
        label: "Dashboard",
        url: "/dashboard",
        Icon: PieChart,
    },
    {
        id: 2,
        label: "Listados",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: 21,
                label: "Vehiculos",
                url: "/vehicles",
                Icon: Calendar,
            },
            {
                id: 22,
                label: "Empleados",
                url: "/employees",
                Icon: MessageSquare,
            },
            {
                id: 23,
                label: "Grupos",
                url: "/groups",
                Icon: Users,
            },
        ],
    },
    {
        id: 3,
        label: "Interfaz de usuario",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: 31,
                label: "Reportes",
                url: "https://doar-react-components.netlify.app/?path=/docs/introduction--page",
                Icon: Box,
            },
            {
                id: 32,
                label: "Consultas",
                url: "#!",
                Icon: Archive,
            },
        ],
    },
];

export default asideMenus;
