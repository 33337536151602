import CreateCompanyForm from "../../components/create-company-form";
import { StyledMedia } from "./style";

const AuthContainer = () => {
    return (
        <StyledMedia>
            <CreateCompanyForm />
        </StyledMedia>
    );
};

export default AuthContainer;
