import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorNotFound from "../pages/error-404";
import PrivateRouteWrapper from "./private-route-wrapper";
import { useAppSelector } from "../redux/hooks";

const DashboardOne = lazy(() => import("../pages/dashboard/dashboard-one"));
const Profile = lazy(() => import("../pages/dashboard/profile"));
const Clients = lazy(() => import("../pages/dashboard/Clients"));
const Groups = lazy(() => import("../pages/dashboard/groups/groups-list"));
const GlobalTransactions = lazy(
    () => import("../pages/dashboard/global-transactions")
);
const ListVehicles = lazy(
    () => import("../pages/dashboard/vehicles/vehicles-list")
);
const NewVehicle = lazy(
    () => import("../pages/dashboard/vehicles/vehicle-create")
);
const Vehicle = lazy(() => import("../pages/dashboard/vehicles/vehicle"));
const EmployeesList = lazy(
    () => import("../pages/dashboard/employees/employees-list")
);
const Employee = lazy(() => import("../pages/dashboard/employees/employee"));
const EmployeeCreate = lazy(
    () =>
        import(
            "../pages/dashboard/employees/employee-create/employee-create-layout"
        )
);

const Bills = lazy(() => import("../pages/billing/bills"));
const Tags = lazy(() => import("../pages/dashboard/tags/tags"));

const FleetRoutes = () => {
    const { loginInfo } = useAppSelector((state) => state.auth);
    const EMPLOYEE_HASH = loginInfo.hash_employee || "";
    const navigateToLocation = EMPLOYEE_HASH
        ? `/app/fleet/employees/${EMPLOYEE_HASH}`
        : `/app/choose`;
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <Navigate to="/app/fleet/dashboard" />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <DashboardOne />
                    </PrivateRouteWrapper>
                }
            />

            {/* User Routes */}
            <Route path="/profile" element={<Profile />} />
            <Route
                path="/client-profile"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <Clients />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/groups"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <Groups />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/transactions"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <GlobalTransactions />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/bills/*"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <Bills />
                    </PrivateRouteWrapper>
                }
            />
            <Route path="/tags" element={<Tags />} />

            <Route
                path="/employees"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <EmployeesList />
                    </PrivateRouteWrapper>
                }
            />
            <Route path="/employees/:id" element={<Employee />} />
            <Route
                path="/employees/create"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <EmployeeCreate />
                    </PrivateRouteWrapper>
                }
            />

            <Route
                path="/vehicles"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <ListVehicles />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/vehicles/:hash"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <Vehicle />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/vehicles/create"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fleet"]}
                        navigateTo={navigateToLocation}
                    >
                        <NewVehicle />
                    </PrivateRouteWrapper>
                }
            />

            {/* <Route path="/*" element={<Navigate to="/app/dashboard" />} /> */}
            <Route path="/*" element={<ErrorNotFound />} />
        </Routes>
    );
};

export default FleetRoutes;
