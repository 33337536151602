import { Col, Row, Text, Button } from "@doar/components";
import { useNavigate } from "react-router-dom";
// import PricingTable from "../components/pricing-table";
import SEO from "../components/seo";
import Layout from "../layouts";
import Content from "../layouts/content";
import { StyledIcon } from "../components/pricing-table/style";
import { useAppDispatch } from "../redux/hooks";
import { startSetProductPlatformSelection } from "../redux/thunks/auth";

export const ProductsPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleFleetSelection = () => {
        dispatch(startSetProductPlatformSelection("fleet"));
        navigate("/app/auth/signup");
    };

    const handleFuelSelection = () => {
        dispatch(startSetProductPlatformSelection("fuel"));
        navigate("/app/auth/signup");
    };

    return (
        <Layout>
            <SEO />
            <Content fullHeight align="center">
                <Text fontSize="1.5rem" textAlign="center">
                    Elige el producto para ti
                </Text>
                <br />
                <Row justifyContent="center" alignItems="stretch">
                    <Col col={10} sm={6} md={4} lg={3}>
                        <div
                            style={{
                                border: "1px solid #ccc",
                                padding: "1rem",
                                borderRadius: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <StyledIcon>
                                    <i className={`fa fa-truck`} />
                                </StyledIcon>
                                <h2>Flotillas</h2>
                                <strong style={{ textAlign: "justify" }}>
                                    ¡Únete a Citygas y simplifica la gestión de
                                    tus necesidades de combustible!
                                </strong>
                                <span style={{ textAlign: "justify" }}>
                                    Citygas ofrece un portal intuitivo y fácil
                                    de usar para flotillas, que les permite
                                    gestionar sus necesidades de combustible de
                                    manera eficiente y segura.
                                </span>
                                <span style={{ textAlign: "justify" }}>
                                    Con el uso de tecnología avanzada de NFC,
                                    RFID y autenticación de doble factor, los
                                    clientes de Citygas pueden autorizar
                                    transacciones y controlar sus consumos de
                                    combustible de manera remota. Además, con la
                                    capacidad de almacenar millones de
                                    transacciones, Citygas les permite a las
                                    flotillas tener un registro completo y
                                    accesible de sus consumos de combustible en
                                    todo momento.
                                </span>
                            </div>
                            <div>
                                <Button
                                    mt="1rem"
                                    onClick={handleFleetSelection}
                                >
                                    Elegir flotillas
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col col={10} sm={6} md={4} lg={3} mt={["40px", 0]}>
                        <div
                            style={{
                                border: "1px solid #ccc",
                                padding: "1rem",
                                borderRadius: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <StyledIcon>
                                    <i className={`fa fa-gas-pump`} />
                                </StyledIcon>
                                <h2>Gasolineras</h2>
                                <strong style={{ textAlign: "justify" }}>
                                    ¡Únete a Citygas y amplía tus oportunidades
                                    de negocio!
                                </strong>
                                <span style={{ textAlign: "justify" }}>
                                    Citygas ofrece una plataforma única para
                                    gasolineros que permite registrar sus
                                    estaciones de combustible y ampliar su
                                    público al ofrecer sus productos a los
                                    clientes afiliados a Citygas.
                                </span>
                                <span style={{ textAlign: "justify" }}>
                                    Esta alianza es una oportunidad para los
                                    gasolineros de aumentar sus ingresos y
                                    crecer su negocio, ya que pueden atraer a
                                    nuevos clientes a través del sistema de
                                    flotillas administrado por Citygas. Con la
                                    seguridad y tecnología avanzada de
                                    autenticación de doble factor, los
                                    gasolineros pueden estar seguros de que sus
                                    transacciones son confiables y seguras.
                                </span>
                            </div>
                            <div>
                                <Button mt="1rem" onClick={handleFuelSelection}>
                                    Elegir gasolineras
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col col={10} sm={6} md={4} lg={3} mt={["40px", null, 0]}>
                        <div
                            style={{
                                border: "1px solid #ccc",
                                padding: "1rem",
                                borderRadius: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <StyledIcon>
                                    <i className={`fa fa-handshake`} />
                                </StyledIcon>
                                <h2>Partners</h2>
                                <strong style={{ textAlign: "justify" }}>
                                    Trabajar con Citygas significa una mayor
                                    exposición y una mayor oportunidad de
                                    crecimiento para nuestros partners
                                    proveedores de descuentos y descuentos.
                                </strong>
                                <span style={{ textAlign: "justify" }}>
                                    Citygas puede ofrecer una alianza a sus
                                    partners, proveedores de descuentos y
                                    descuentos, al proporcionarles una
                                    plataforma para promocionar sus productos y
                                    servicios a una audiencia amplia y en
                                    constante crecimiento.
                                </span>
                                <span style={{ textAlign: "justify" }}>
                                    Al trabajar con Citygas, los proveedores de
                                    descuentos pueden ofrecer descuentos
                                    especiales y promociones exclusivas a los
                                    clientes de Citygas, lo que les permite
                                    llegar a un público más amplio y aumentar su
                                    visibilidad. Además, los clientes de Citygas
                                    se benefician al recibir descuentos y
                                    promociones exclusivas que les permiten
                                    ahorrar en sus compras de combustible.
                                </span>
                            </div>
                            <div>
                                <Button
                                    mt="1rem"
                                    onClick={() => navigate("/contact")}
                                >
                                    Contáctanos
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};
