/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type loginInfo = {
    email: string | null;
    id: string | null;
    image_name: string | null;
    image_url: string | null;
    client_id: string | null;
    user_groups: string[] | null;
    token: string | null;
    fleetAdmin: boolean;
    fuelAdmin: boolean;
    superAdmin: boolean;
    hash_employee: string | null;
    validation_status: string | null;
};

export interface AuthState {
    status: "checking" | "not-authenticated" | "authenticated";
    loginInfo: loginInfo;
    timeZone: string | null;
    selectedProductPlatform: string | null;
    errorMessage: string | null;
}

const initialState: AuthState = {
    status: "checking",
    loginInfo: {
        email: null,
        id: null,
        image_name: null,
        image_url: null,
        client_id: null,
        user_groups: null,
        token: null,
        fleetAdmin: false,
        fuelAdmin: false,
        superAdmin: false,
        hash_employee: null,
        validation_status: null,
    },
    selectedProductPlatform: null,
    timeZone: null,
    errorMessage: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setTimezone(state, action: PayloadAction<{ timeZone: string }>) {
            state.timeZone = action.payload.timeZone;
        },
        login(state, action: PayloadAction<{ loginInfo: loginInfo }>) {
            state.status = "authenticated";
            state.loginInfo = {
                ...action.payload.loginInfo,
                fleetAdmin:
                    action.payload.loginInfo.user_groups?.includes("fleet") ||
                    action.payload.loginInfo.user_groups?.includes("admin") ||
                    false,
                fuelAdmin:
                    action.payload.loginInfo.user_groups?.includes("fuel") ||
                    action.payload.loginInfo.user_groups?.includes("admin") ||
                    false,
                superAdmin:
                    action.payload.loginInfo.user_groups?.includes("admin") ||
                    false,
            };
        },
        logout(state, action: PayloadAction<{ errorMessage: string }>) {
            state.status = "not-authenticated";
            state.loginInfo = {
                email: null,
                id: null,
                image_name: null,
                image_url: null,
                client_id: null,
                user_groups: null,
                token: null,
                fleetAdmin: false,
                fuelAdmin: false,
                superAdmin: false,
                hash_employee: null,
                validation_status: null,
            };
            state.errorMessage = action.payload.errorMessage;
        },
        setSelectedProductPlatform(
            state,
            action: PayloadAction<{ selectedProductPlatform: string }>
        ) {
            state.selectedProductPlatform =
                action.payload.selectedProductPlatform;
        },
        clearSelectedProductPlatform(state) {
            state.selectedProductPlatform = null;
        },
        checkingCredentials(state) {
            state.status = "checking";
        },
    },
});

export const {
    setTimezone,
    login,
    logout,
    setSelectedProductPlatform,
    clearSelectedProductPlatform,
    checkingCredentials,
} = authSlice.actions;
export default authSlice.reducer;
