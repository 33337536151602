import axios from "axios";
import Cookies from "js-cookie";

const cityGasAPI = axios.create({
    baseURL: process.env.REACT_APP_CITYGAS_API_URL,
});

/*cityGasAPI.interceptors.response.use((response) => {
    if (response.config.url === "login") {
        cityGasAPI.defaults.headers.common = {
            "X-API-KEY": response.data.api_key,
        };
    }
    return response;
});*/

cityGasAPI.interceptors.request.use((config) => {
    const session = JSON.parse((Cookies.get("auth2") as string) || "null");

    if (!session) return config;

    return {
        ...config,
        headers: {
            "X-API-KEY": session.token,
            ...config.headers,
        },
    };
});

export { cityGasAPI };
