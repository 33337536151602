import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorNotFound from "../pages/error-404";
import PrivateRouteWrapper from "./private-route-wrapper";
import { useAppSelector } from "../redux/hooks";

const DashboardStations = lazy(
    () => import("../pages/dashboard/dashboard-stations")
    //() => import("../modules/fuel/dashboard")
);

const Profile = lazy(() => import("../pages/dashboard/profile"));
const Clients = lazy(() => import("../pages/dashboard/Clients"));

const EmployeesList = lazy(
    () => import("../pages/stations/employees/employees-list")
);
const Employee = lazy(() => import("../pages/stations/employees/employee"));
const EmployeeCreate = lazy(
    () =>
        import(
            "../pages/stations/employees/employee-create/employee-create-layout"
        )
);

const NewStation = lazy(
    () => import("../pages/stations/stations/station-create")
);
const Station = lazy(() => import("../pages/stations/stations/station"));
const ListStations = lazy(
    () => import("../pages/stations/stations/stations-list")
);
const Invoices = lazy(() => import("../pages/stations/bills/station-bills"));

const Products = lazy(
    () => import("../pages/stations/products/product-list-layout")
);

const Transactions = lazy(
    () => import("../pages/dashboard/global-transactions")
);

const FuelRoutes = () => {
    const { loginInfo } = useAppSelector((state) => state.auth);
    const EMPLOYEE_HASH = loginInfo.hash_employee || "";
    const navigateToLocation = EMPLOYEE_HASH
        ? `/app/fuel/employees/${EMPLOYEE_HASH}`
        : `/app/choose`;
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Navigate to="/app/fuel/dashboard" />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <DashboardStations />
                    </PrivateRouteWrapper>
                }
            />

            <Route path="/profile" element={<Profile />} />
            <Route
                path="/client-profile"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Clients />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/employees"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <EmployeesList />
                    </PrivateRouteWrapper>
                }
            />
            <Route path="/employees/:id" element={<Employee />} />
            <Route
                path="/employees/create"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <EmployeeCreate />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/bills/*"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Invoices />
                    </PrivateRouteWrapper>
                }
            />

            <Route
                path="/stations"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <ListStations />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/stations/:hash"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Station />
                    </PrivateRouteWrapper>
                }
            />
            <Route
                path="/stations/create"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <NewStation />
                    </PrivateRouteWrapper>
                }
            />

            <Route
                path="/products"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Products />
                    </PrivateRouteWrapper>
                }
            />

            <Route
                path="/transactions"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["fuel"]}
                        navigateTo={navigateToLocation}
                    >
                        <Transactions client_area={"Fuel"} />
                    </PrivateRouteWrapper>
                }
            />

            <Route path="/*" element={<ErrorNotFound />} />
        </Routes>
    );
};

export default FuelRoutes;
