import { useState } from "react";
import { Button } from "@doar/components";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { startSetProductPlatformSelection } from "../../../../redux/thunks/auth";

export const SelectPlatformProduct = () => {
    const [visible, setVisible] = useState(false);
    const { selectedProductPlatform } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const [selectedProduct, setSelectedProduct] = useState(
        selectedProductPlatform || ""
    );

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    };

    const handleSelection = () => {
        dispatch(startSetProductPlatformSelection(selectedProduct));
        setVisible(false);
    };

    return (
        <>
            {!visible ? (
                <div>
                    <p>Producto seleccionado</p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <p
                            style={{
                                fontSize: "1rem",
                                color: "#042893",
                                fontWeight: 600,
                            }}
                        >
                            {selectedProductPlatform === "fleet"
                                ? "Flotillas"
                                : selectedProductPlatform === "fuel"
                                ? "Gasolineros"
                                : "Ninguno"}
                        </p>
                        <Button
                            color="primary"
                            onClick={() => setVisible(true)}
                        >
                            Cambiar selección
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <h6>Selecciona un producto:</h6>
                    <select
                        value={selectedProduct}
                        onChange={handleSelectChange}
                    >
                        <option disabled value="">
                            Selecciona un producto
                        </option>
                        <option value="fleet">Flotillas</option>
                        <option value="fuel">Gasolineros</option>
                    </select>
                    <div
                        style={{
                            margin: "1rem 0",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            size="sm"
                            onClick={handleSelection}
                            disabled={selectedProduct === ""}
                        >
                            Guardar selección
                        </Button>
                        <Button
                            size="sm"
                            color="danger"
                            onClick={() => setVisible(false)}
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
