import styled from "styled-components";

export const TooltipWrapper = styled.div<{
    $position: { x: number; y: number };
    $offsetLeft: number;
    $offsetTop: number;
}>`
    position: absolute;
    background-color: #333;
    color: #fff;
    overflow: hidden;
    font-size: 12px;
    border-radius: 3px;
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    z-index: 10;
`;

export const TooltipContainer = styled.div`
    position: relative;
    width: fit-content;
    height: auto;
    &:hover ${TooltipWrapper} {
        opacity: 1;
        height: auto;
        padding: 5px;
    }
    &:focus ${TooltipWrapper} {
        display: block;
    }
    &:focus-visible ${TooltipWrapper} {
        display: block;
    }
`;
