import { useState } from "react";
import { AxiosResponse } from "axios";
import {
    IUpdateCompanyDocumentsResponse,
    IUpdatedCompanyDocuments,
} from "../../interfaces/update-company-documents/updateCompanyDocumentsResponse";
import { cityGasAPI } from "../../apis";

export const useUpdateCompanyDocuments = () => {
    const [updatedCompanyDocuments, setUpdatedCompanyDocuments] =
        useState<IUpdatedCompanyDocuments>({} as IUpdatedCompanyDocuments);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    const updateCompanyDocuments = async ({
        client_id,
        rfc,
        proof_of_tax_domain,
        constitutive_act,
        token,
    }: {
        client_id: string;
        rfc: string;
        proof_of_tax_domain: File;
        constitutive_act: File;
        token: string;
    }) => {
        setLoading(true);
        try {
            console.log("proof_of_tax_domain", proof_of_tax_domain);
            console.log("constitutive_act", constitutive_act);
            const formData = new FormData();
            formData.append("client_id", client_id);
            formData.append("rfc", rfc);
            const proofOfTaxDomainExt = proof_of_tax_domain.name.slice(
                proof_of_tax_domain.name.lastIndexOf(".") + 1
            );
            const proofOfTaxDomainMime = proof_of_tax_domain.type;
            const constitutiveActExt = constitutive_act.name.slice(
                constitutive_act.name.lastIndexOf(".") + 1
            );
            const constitutiveActMime = constitutive_act.type;

            const proofOfTaxDomainDocument = new File(
                [proof_of_tax_domain],
                `proof_of_tax_domain_${rfc}.${proofOfTaxDomainExt}`,
                {
                    type: proofOfTaxDomainMime,
                    lastModified: new Date().getTime(),
                }
            );

            const constitutiveActDocument = new File(
                [constitutive_act],
                `constitutive_act_${rfc}.${constitutiveActExt}`,
                {
                    type: constitutiveActMime,
                    lastModified: new Date().getTime(),
                }
            );

            formData.append(
                "proof_of_tax_domain",
                proofOfTaxDomainDocument,
                `proof_of_tax_domain_${rfc}.${proofOfTaxDomainExt}`
            );
            formData.append(
                "constitutive_act",
                constitutiveActDocument,
                `constitutive_act_${rfc}.${constitutiveActExt}`
            );

            const response: AxiosResponse<IUpdateCompanyDocumentsResponse> =
                await cityGasAPI.post("clients/update", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-API-KEY": token,
                    },
                });
            setUpdatedCompanyDocuments(response.data.response);
        } catch (err) {
            setError(err);
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return { updatedCompanyDocuments, loading, error, updateCompanyDocuments };
};
