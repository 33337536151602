import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "../pages/auth/signin";
import SignUp from "../pages/auth/signup";
import VerifyAccount from "../pages/auth/verify-account";
import ForgotPassword from "../pages/auth/forgot-password";
import { Affiliate } from "../pages/auth/affiliate";
import { useAppSelector } from "../redux/hooks";
import { CreateCompany } from "../pages/auth/create-company";

const AuthRoutes = () => {
    const { loginInfo } = useAppSelector((state) => state.auth);

    return (
        <Routes>
            {loginInfo.token &&
                !loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "2" && (
                    <>
                        <Route
                            path="create-company"
                            element={<CreateCompany />}
                        />
                    </>
                )}

            {loginInfo.token &&
                loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "2" && (
                    <>
                        <Route path="affiliate" element={<Affiliate />} />
                    </>
                )}

            {loginInfo.token &&
                loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "0" && (
                    <>
                        <Route
                            path="verification-process"
                            element={<VerifyAccount />}
                        />
                    </>
                )}

            {!loginInfo.token && (
                <>
                    <Route path="signin" element={<SignIn />} />
                    <Route path="signup" element={<SignUp />} />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                </>
            )}

            {loginInfo.token &&
                !loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "2" && (
                    <Route
                        path="/*"
                        element={<Navigate to="/app/auth/create-company" />}
                    />
                )}

            {loginInfo.token &&
                loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "2" && (
                    <Route
                        path="/*"
                        element={<Navigate to="/app/auth/affiliate" />}
                    />
                )}

            {loginInfo.token &&
                loginInfo.client_id &&
                loginInfo.validation_status &&
                loginInfo.validation_status.toString() === "0" && (
                    <Route
                        path="/*"
                        element={
                            <Navigate to="/app/auth/verification-process" />
                        }
                    />
                )}

            {!loginInfo.token && (
                <Route path="/*" element={<Navigate to="/app/auth/signin" />} />
            )}
        </Routes>
    );
};

export default AuthRoutes;
