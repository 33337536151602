import { AxiosResponse } from "axios";
import { useState } from "react";
import { cityGasAPI } from "../../apis";
import {
    ICompanyCreateResponse,
    ICompanyCreated,
} from "../../interfaces/create-company/companyCreateResponse";

export const useCreateCompany = () => {
    const [companyCreated, setCompanyCreated] = useState<ICompanyCreated>(
        {} as ICompanyCreated
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    const createCompany = async ({
        token,
        account_number,
        address1,
        address2,
        zip_code,
        state,
        city,
        phone,
        email,
        group_id,
        bank,
        balance,
        available_credit,
        credit_limit,
        latitude,
        longitude,
        tags,
        authorization_method,
        charge_authorization_schedule,
        can_charge,
        username,
        password,
        is_invoiced,
        canonical_name,
        legal_name,
        exterior_number,
        interior_number,
        full_name,
        nickname,
        shortname,
        image_name,
        image_url,
    }: {
        token: string;
        account_number: string | null;
        address1: string;
        address2: string;
        zip_code: string;
        state: string;
        city: string;
        phone: string;
        email: string;
        bank?: string;
        group_id?: string;
        balance?: string;
        available_credit?: string;
        credit_limit?: string;
        latitude?: string;
        longitude?: string;
        tags?: string;
        authorization_method?: string;
        charge_authorization_schedule?: string;
        can_charge?: string;
        username?: string;
        password?: string;
        is_invoiced?: string;
        canonical_name: string;
        legal_name: string;
        exterior_number: string;
        interior_number: string;
        full_name?: string;
        nickname?: string;
        shortname?: string;
        image_name?: string;
        image_url?: string;
    }) => {
        setLoading(true);
        try {
            const response: AxiosResponse<ICompanyCreateResponse> =
                await cityGasAPI.post(
                    "/clients/create",
                    {
                        account_number,
                        address1,
                        address2,
                        zip_code,
                        state,
                        city,
                        phone,
                        email,
                        group_id,
                        canonical_name,
                        legal_name,
                        exterior_number,
                        interior_number,
                        bank,
                        balance,
                        available_credit,
                        credit_limit,
                        latitude,
                        longitude,
                        tags,
                        authorization_method,
                        charge_authorization_schedule,
                        can_charge,
                        username,
                        password,
                        is_invoiced,
                        full_name,
                        nickname,
                        shortname,
                        image_name,
                        image_url,
                    },
                    {
                        headers: {
                            "X-API-KEY": token,
                        },
                    }
                );
            setCompanyCreated(response.data.response);
        } catch (err) {
            setError(err);
            console.log(err);
        }
        setLoading(false);
    };

    return { createCompany, companyCreated, loading, error };
};
