import { Button } from "@doar/components";
import image from "@doar/shared/images/img17.png";
import {
    StyledWrap,
    StyledImg,
    StyledTitle,
    StyledDesc,
    StyledBtnWrap,
} from "./style";

const VerifyContainer = () => {
    return (
        <StyledWrap>
            <StyledImg>
                <img src={image} alt="verify" />
            </StyledImg>
            <StyledTitle>Estás en proceso de verificación</StyledTitle>
            <StyledDesc>
                Por favor revisa tu correo electrónico constantemente donde te
                haremos saber cuando tu cuenta sea verificada. Regularmente toma
                de 1 a 3 días hábiles.
            </StyledDesc>
            <StyledBtnWrap>
                <Button path="/contact" color="white" ml="5px">
                    Contáctanos
                </Button>
            </StyledBtnWrap>
        </StyledWrap>
    );
};

export default VerifyContainer;
