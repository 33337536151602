import { Col, Heading, Nav, NavLink, Row, Text } from "@doar/components";
import styled, { themeGet } from "@doar/shared/styled";
import Content from "../layouts/content";
import Breadcrumb from "../components/breadcrumb";
import SEO from "../components/seo";
import Layout from "../layouts";
import { useState } from "react";

export const StyleLabel = styled.h6`
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    color: ${themeGet("colors.text3")};
    margin-bottom: 15px;
`;

export const AboutPage = () => {
    const [current, setCurrent] = useState(0);
    return (
        <Layout>
            <SEO />
            <Content>
                <Row>
                    <Col lg={3} xl={2} mb={["20px", null, null, 0]}>
                        <StyleLabel>Acerca de CityGas</StyleLabel>
                        <Nav customStyle="classic">
                            <NavLink
                                path="#history"
                                onClick={() => setCurrent(0)}
                                active={current === 0}
                            >
                                Historia
                            </NavLink>
                            <NavLink
                                path="#mission"
                                onClick={() => setCurrent(1)}
                                active={current === 1}
                            >
                                Misión
                            </NavLink>
                            <NavLink
                                path="#vision"
                                onClick={() => setCurrent(2)}
                                active={current === 2}
                            >
                                Visión
                            </NavLink>
                            <NavLink
                                path="#technology"
                                onClick={() => setCurrent(3)}
                                active={current === 3}
                            >
                                Tecnología
                            </NavLink>
                        </Nav>
                    </Col>
                    <Col lg={9} xl={10}>
                        <Breadcrumb
                            prev={[
                                { text: "Dashboard", link: "/" },
                                { text: "Acerca de", link: "/about" },
                            ]}
                            title={
                                current === 0
                                    ? "Historia"
                                    : current === 1
                                    ? "Misión"
                                    : current === 2
                                    ? "Visión"
                                    : "Tecnología"
                            }
                        />
                        <Heading as="h3" mb="25px">
                            {current === 0
                                ? "Historia"
                                : current === 1
                                ? "Misión"
                                : current === 2
                                ? "Visión"
                                : "Tecnología"}
                        </Heading>
                        <Row>
                            {current === 0 ? (
                                <Col sm={12}>
                                    <div
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            textAlign: "justify",
                                        }}
                                    >
                                        <p>
                                            La historia de Citygas comienza con
                                            un grupo de empresarios expertos en
                                            el sector de combustible y en la
                                            industria de innovación en
                                            tecnología.
                                        </p>
                                        <p>
                                            {" "}
                                            Con más de 20 años de experiencia en
                                            la industria, estos líderes
                                            decidieron unir sus habilidades para
                                            crear una solución innovadora para
                                            la gestión de flotillas de
                                            vehículos. En su búsqueda por
                                            resolver los problemas comunes que
                                            enfrentan las empresas que dependen
                                            del combustible para sus flotillas,
                                            descubrieron que la mayoría de las
                                            soluciones actuales eran
                                            ineficientes y costosas. Por esta
                                            razón, decidieron crear una empresa
                                            que combinara la experiencia en el
                                            sector de combustible con la
                                            tecnología más avanzada para ofrecer
                                            una solución única y eficiente.
                                        </p>
                                        <p>
                                            Así nació Citygas, una empresa que
                                            se dedica a la administración de
                                            flotillas a través del crédito de
                                            combustible. Utilizando tecnología
                                            de vanguardia, como NFC, QR, SMS,
                                            WhatsApp, Microsoft Teams, Slack y
                                            NIP, Citygas proporciona un proceso
                                            de autenticación de doble factor
                                            para garantizar la seguridad de las
                                            transacciones y el acceso a la
                                            información de consumo de
                                            combustible. Desde su creación,
                                            Citygas se ha establecido como un
                                            líder en la industria, gracias a su
                                            capacidad de almacenar millones de
                                            transacciones y ofrecer una solución
                                            fácil de usar en sistemas operativos
                                            iOS, Android y Web.
                                        </p>
                                        <p>
                                            La empresa sigue evolucionando para
                                            satisfacer las necesidades
                                            cambiantes del mercado y mantenerse
                                            a la vanguardia de la innovación en
                                            la industria de combustible. La
                                            historia de Citygas es un ejemplo de
                                            cómo la experiencia y la tecnología
                                            pueden unirse para ofrecer
                                            soluciones innovadoras que mejoran
                                            la eficiencia y la efectividad de
                                            las empresas.
                                        </p>
                                    </div>
                                </Col>
                            ) : current === 1 ? (
                                <Col sm={12}>
                                    <div
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            textAlign: "justify",
                                        }}
                                    >
                                        <p>
                                            Nuestra misión en Citygas es
                                            proporcionar soluciones innovadoras
                                            y seguras para la gestión de
                                            combustible para flotillas de
                                            vehículos. Nos esforzamos por
                                            mejorar la eficiencia y seguridad en
                                            las transacciones de combustible, a
                                            través del uso de tecnología
                                            avanzada y autenticación de doble
                                            factor.
                                        </p>
                                        <p>
                                            Nos enfocamos en ofrecer un servicio
                                            personalizado y atento a las
                                            necesidades de nuestros clientes,
                                            con el objetivo de mejorar su
                                            experiencia y ahorrar tiempo y
                                            recursos. Trabajamos incansablemente
                                            para ser la opción líder en la
                                            industria del crédito de
                                            combustible. También facilitar el
                                            acceso al consumo de energía para el
                                            autotransporte y gestionar la
                                            información periférica facilitándole
                                            al empresario, individuo y gobierno
                                            las herramientas tecnológicas para
                                            lograrlo.
                                        </p>
                                    </div>
                                </Col>
                            ) : current === 2 ? (
                                <Col sm={12}>
                                    <div
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            textAlign: "justify",
                                        }}
                                    >
                                        <p>
                                            Nuestra visión en Citygas es ser
                                            reconocidos como una empresa líder
                                            en el mercado del crédito de
                                            combustible en México. Nos
                                            proponemos revolucionar la forma en
                                            que las flotillas de vehículos
                                            gestionan su combustible, ofreciendo
                                            soluciones innovadoras y seguras que
                                            mejoren la eficiencia y reduzcan los
                                            riesgos de seguridad.
                                        </p>
                                        <p>
                                            Queremos ser un socio confiable para
                                            nuestros clientes, ayudándolos a
                                            ahorrar tiempo y recursos, y mejorar
                                            su experiencia en la gestión de
                                            combustible. En el futuro, nos
                                            enfocaremos en expandir nuestra
                                            presencia en el mercado mexicano y
                                            consolidarnos como la opción
                                            preferida para el crédito de
                                            combustible.
                                        </p>
                                    </div>
                                </Col>
                            ) : (
                                <>
                                    <Col sm={12}>
                                        <div
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            <p>
                                                Citygas es una empresa de
                                                crédito de combustible para
                                                flotillas que ofrece soluciones
                                                innovadoras y seguras para la
                                                gestión de combustible.
                                            </p>
                                            <p>
                                                Con un enfoque en la tecnología,
                                                Citygas utiliza una amplia gama
                                                de métodos de autenticación de
                                                doble factor, incluyendo RFID,
                                                NFC, QR, SMS, WhatsApp,
                                                Microsoft Teams, Slack y NIP,
                                                para garantizar la seguridad de
                                                cada transacción. Además,
                                                Citygas es compatible con
                                                sistemas operativos iOS, Android
                                                y Web, lo que permite una
                                                accesibilidad fácil y
                                                conveniente desde cualquier
                                                dispositivo.
                                            </p>
                                            <p>
                                                La empresa también tiene una
                                                capacidad de almacenamiento
                                                masiva, lo que le permite
                                                registrar y almacenar millones
                                                de transacciones de combustible
                                                sin perder ninguna información
                                                importante. Con esta tecnología
                                                avanzada, Citygas es una opción
                                                confiable y eficiente para la
                                                gestión de combustible para
                                                flotillas de vehículos. La
                                                empresa se esfuerza por mejorar
                                                la eficiencia y reducir los
                                                riesgos de seguridad en cada
                                                transacción de combustible, y su
                                                tecnología es un reflejo de su
                                                compromiso con la innovación y
                                                la excelencia.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            <Text fontWeight={500}>SMS</Text>
                                            <p>
                                                El autenticación de doble factor
                                                (2FA) con SMS se refiere al uso
                                                de un mensaje de texto enviado a
                                                un número de teléfono para
                                                confirmar la identidad del
                                                usuario. En este caso, el número
                                                de teléfono es utilizado como el
                                                segundo factor de autenticación,
                                                después de la contraseña o el
                                                nombre de usuario. Aquí es cómo
                                                funciona: cuando un usuario
                                                intenta acceder a una cuenta o
                                                realizar una transacción,
                                                primero es necesario
                                                proporcionar su nombre de
                                                usuario y contraseña. A
                                                continuación, se envía un
                                                mensaje de texto con un código
                                                temporal al número de teléfono
                                                registrado en la cuenta. El
                                                usuario debe ingresar este
                                                código en la plataforma o
                                                aplicación para completar la
                                                autenticación. Esto proporciona
                                                una capa adicional de seguridad
                                                ya que, incluso si un atacante
                                                conoce la contraseña de un
                                                usuario, aún necesitará acceso a
                                                su número de teléfono para
                                                completar la transacción. La
                                                autenticación de doble factor
                                                con SMS es una solución práctica
                                                y fácil de usar para mejorar la
                                                seguridad en línea.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            <Text fontWeight={500}>RFID</Text>
                                            <p>
                                                El RFID (Radio Frequency
                                                Identification) es una
                                                tecnología que permite la
                                                identificación a distancia de
                                                objetos mediante la transmisión
                                                de radiofrecuencia. En el caso
                                                de la autenticación, se utiliza
                                                una tarjeta o dispositivo RFID
                                                para proporcionar acceso a una
                                                cuenta o realizar una
                                                transacción. Aquí es cómo
                                                funciona: el usuario posee una
                                                tarjeta o dispositivo RFID que
                                                es registrado previamente en un
                                                sistema. Cuando el usuario desea
                                                acceder a una cuenta o realizar
                                                una transacción, debe acercar su
                                                tarjeta RFID a un lector, que
                                                leerá la información almacenada
                                                en el dispositivo. Si la
                                                información coincide con la
                                                información registrada en el
                                                sistema, el usuario es
                                                autenticado y permitido acceder
                                                a la cuenta o realizar la
                                                transacción.
                                            </p>
                                            <p>
                                                El uso de la tecnología RFID
                                                como método de autenticación
                                                proporciona una solución rápida
                                                y conveniente para la seguridad,
                                                ya que el usuario solo necesita
                                                acercar su tarjeta RFID para
                                                completar la autenticación, sin
                                                tener que ingresar contraseñas o
                                                códigos. Además, al utilizar un
                                                dispositivo físico, se añade una
                                                capa adicional de seguridad para
                                                proteger la información y
                                                prevenir el acceso no
                                                autorizado.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            <Text fontWeight={500}>NFC</Text>
                                            <p>
                                                El NFC (Near Field
                                                Communication) es una tecnología
                                                que permite la comunicación
                                                inalámbrica a corta distancia
                                                entre dispositivos. En el caso
                                                de la autenticación, se utiliza
                                                un dispositivo NFC para
                                                proporcionar acceso a una cuenta
                                                o realizar una transacción. Aquí
                                                es cómo funciona: el usuario
                                                posee un dispositivo NFC, como
                                                un teléfono inteligente, que es
                                                registrado previamente en un
                                                sistema. Cuando el usuario desea
                                                acceder a una cuenta o realizar
                                                una transacción, debe acercar su
                                                dispositivo NFC a un lector NFC,
                                                que leerá la información
                                                almacenada en el dispositivo. Si
                                                la información coincide con la
                                                información registrada en el
                                                sistema, el usuario es
                                                autenticado y permitido acceder
                                                a la cuenta o realizar la
                                                transacción. El uso de la
                                                tecnología NFC como método de
                                                autenticación proporciona una
                                                solución práctica y conveniente
                                                para la seguridad, ya que el
                                                usuario solo necesita acercar su
                                                dispositivo NFC para completar
                                                la autenticación, sin tener que
                                                ingresar contraseñas o códigos.
                                                Además, al utilizar un
                                                dispositivo conectado a
                                                Internet, se añade una capa
                                                adicional de seguridad para
                                                proteger la información y
                                                prevenir el acceso no
                                                autorizado.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                textAlign: "justify",
                                            }}
                                        >
                                            <Text fontWeight={500}>
                                                WhatsApp, Teams, Slack
                                            </Text>
                                            <p>
                                                WhatsApp, Microsoft Teams y
                                                Slack son plataformas de
                                                comunicación instantánea que
                                                permiten a los usuarios enviar
                                                mensajes, hacer llamadas y
                                                compartir archivos en tiempo
                                                real. Estos servicios pueden ser
                                                utilizados como parte de un
                                                proceso de autenticación de
                                                doble factor, proporcionando una
                                                capa adicional de seguridad en
                                                la gestión de cuentas y
                                                transacciones.
                                            </p>
                                            <p>
                                                Aquí es cómo funciona: después
                                                de ingresar la información de
                                                acceso en el sistema, el usuario
                                                recibirá una notificación de
                                                confirmación en la plataforma de
                                                comunicación seleccionada, como
                                                WhatsApp, Microsoft Teams o
                                                Slack. La notificación incluirá
                                                un código único que el usuario
                                                debe ingresar en el sistema para
                                                completar la autenticación. Si
                                                el código es correcto, la
                                                autenticación se completará y el
                                                usuario tendrá acceso a la
                                                cuenta o podrá realizar la
                                                transacción. Además, estas
                                                plataformas de comunicación
                                                también pueden ser utilizadas
                                                para notificar a los usuarios
                                                sobre el consumo de combustible
                                                en sus vehículos. Por ejemplo,
                                                pueden recibir un mensaje de
                                                texto en su WhatsApp o Slack
                                                cuando se registre una compra de
                                                combustible en uno de sus
                                                vehículos. Esto proporciona una
                                                solución práctica y conveniente
                                                para monitorear el consumo y
                                                ahorrar tiempo en la gestión de
                                                la flotilla.
                                            </p>
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};
