import { useState } from "react";
import { TooltipWrapper, TooltipContainer } from "./style";

export interface ITooltipOnHoverProps {
    children: React.ReactNode;
    tooltip: React.ReactNode;
    offsetLeft: number;
    offsetTop: number;
    disabled?: boolean;
}

export const TooltipOnHover = ({
    children,
    tooltip,
    offsetLeft,
    offsetTop,
    disabled,
}: ITooltipOnHoverProps) => {
    const [tooltipPosition, setTooltipPosition] = useState({
        x: 0,
        y: 0,
    });

    if (disabled) {
        return <>{children}</>;
    }

    const handleMouseMove = (
        e: React.MouseEvent<HTMLDivElement>,
        tooltipSetter: React.Dispatch<
            React.SetStateAction<{ x: number; y: number }>
        >
    ) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (x >= 0 && y >= 0) {
            tooltipSetter({ x, y });
        }
    };

    const handleMouseLeave = (
        tooltipSetter: React.Dispatch<
            React.SetStateAction<{ x: number; y: number }>
        >
    ) => {
        tooltipSetter({ x: 0, y: 0 });
    };

    return (
        <TooltipContainer
            onMouseMove={(e) => handleMouseMove(e, setTooltipPosition)}
            onMouseLeave={() => handleMouseLeave(setTooltipPosition)}
        >
            <TooltipWrapper
                $position={tooltipPosition}
                $offsetLeft={offsetLeft}
                $offsetTop={offsetTop}
                style={{
                    left: `${offsetLeft + tooltipPosition.x}px`,
                    top: ` ${offsetTop + tooltipPosition.y}px`,
                }}
            >
                {tooltip}
            </TooltipWrapper>
            {children}
        </TooltipContainer>
    );
};
