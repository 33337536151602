import { lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCheckAuth } from "../hooks/check-authorization";
import AuthRoutes from "./auth-routes";
import FleetRoutes from "./fleet-routes";
import FuelRoutes from "./fuel-routes";
import Faqs from "../pages/faqs";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setTimezone } from "../redux/slices/auth";
import PrivateRouteWrapper from "./private-route-wrapper";
import AdminRoutes from "./admin-routes";

const ChoosePlatform = lazy(() => import("../pages/choose-platform"));

const DashboardRoutes = () => {
    const { loginInfo } = useAppSelector((state) => state.auth);
    const status = useCheckAuth();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log("tz", tz);
        dispatch(
            setTimezone({
                timeZone: tz,
            })
        );
    }, [dispatch]);

    return (
        <Routes>
            {/* When the user has already finished all the registration process */}
            {status === "authenticated" &&
            loginInfo.validation_status &&
            loginInfo.validation_status.toString() === "1" &&
            loginInfo.user_groups ? (
                <>
                    <Route path="/" element={<Navigate to="/app/choose" />} />
                    <Route path="/choose" element={<ChoosePlatform />} />
                    <Route
                        path="/fleet/*"
                        element={
                            <PrivateRouteWrapper
                                allowedGroups={["fleet", "fleet_employee"]}
                                navigateTo="/app/choose"
                            >
                                <FleetRoutes />
                            </PrivateRouteWrapper>
                        }
                    />
                    <Route
                        path="/admin/*"
                        element={
                            <PrivateRouteWrapper
                                allowedGroups={["admin"]}
                                navigateTo="/app/choose"
                            >
                                <AdminRoutes />
                            </PrivateRouteWrapper>
                        }
                    />
                    <Route
                        path="/fuel/*"
                        element={
                            <PrivateRouteWrapper
                                allowedGroups={["fuel", "fuel_employee"]}
                                navigateTo="/app/choose"
                            >
                                <FuelRoutes />
                            </PrivateRouteWrapper>
                        }
                    />
                    <Route path="/*" element={<Navigate to="/app" />} />
                    <Route path="/help" element={<Faqs />} />
                </>
            ) : (
                <Route path="/auth/*" element={<AuthRoutes />} />
            )}

            <Route path="/*" element={<Navigate to="/app/auth" />} />
        </Routes>
    );
};

export default DashboardRoutes;
