import { AxiosResponse } from "axios";
import { useState } from "react";
import { cityGasAPI } from "../../apis";
import {
    IUserCreateResponse,
    IUserCreated,
} from "../../interfaces/auth/userCreateResponse";

export const useCreateUser = () => {
    const [userCreated, setUserCreated] = useState<IUserCreated>(
        {} as IUserCreated
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    const createUser = async ({
        username,
        password,
        first_name,
        last_name,
        email,
        phone,
    }: {
        username: string;
        password: string;
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
    }) => {
        setLoading(true);
        try {
            const response: AxiosResponse<IUserCreateResponse> =
                await cityGasAPI.post("/users", {
                    username,
                    password,
                    first_name,
                    last_name,
                    email,
                    phone,
                });
            setUserCreated(response.data.response.info);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setError(err.response.data.error);
            console.log(err);
        }
        setLoading(false);
    };

    return { createUser, userCreated, loading, error };
};
