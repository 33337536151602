import { FormGroup, Label, Input, Anchor, Button } from "@doar/components";
import { useForm, SubmitHandler } from "react-hook-form";
import { hasKey } from "@doar/shared/methods";
import {
    StyledWrap,
    StyledTitle,
    StyledDesc,
    StyledLabelWrap,
    StyledDivider,
    StyledBottomText,
} from "./style";
import { useAppDispatch } from "../../redux/hooks";
import { startLoginWithEmailPassword } from "../../redux/thunks/auth";
import { Logo90px } from "../logo";

interface IFormValues {
    email: string;
    password: string;
}

const SigninForm = () => {
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormValues>();

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        dispatch(startLoginWithEmailPassword(data));
    };

    return (
        <StyledWrap>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Logo90px />
            </div>
            <div style={{ padding: "10px" }}></div>
            <StyledTitle>Inicia sesión</StyledTitle>
            <StyledDesc>
                ¡Bienvenido! Por favor inicia sesión para continuar.
            </StyledDesc>
            <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormGroup mb="20px">
                    <Label display="block" mb="5px" htmlFor="email">
                        Correo electrónico
                    </Label>
                    <Input
                        type="email"
                        id="email"
                        placeholder="yourname@yourmail.com"
                        feedbackText={errors?.email?.message}
                        state={hasKey(errors, "email") ? "error" : "success"}
                        showState={!!hasKey(errors, "email")}
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                </FormGroup>
                <FormGroup mb="20px">
                    <StyledLabelWrap>
                        <Label display="block" mb="5px" htmlFor="password">
                            Contraseña
                        </Label>
                        <Anchor
                            path="/app/auth/forgot-password"
                            fontSize="13px"
                        >
                            ¿Olvidaste tu contraseña?
                        </Anchor>
                    </StyledLabelWrap>
                    <Input
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                        feedbackText={errors?.password?.message}
                        state={hasKey(errors, "password") ? "error" : "success"}
                        showState={!!hasKey(errors, "password")}
                        {...register("password", {
                            required: "Password is required",
                            minLength: {
                                value: 6,
                                message: "Minimum length is 6",
                            },
                            maxLength: {
                                value: 10,
                                message: "Minimum length is 10",
                            },
                        })}
                    />
                </FormGroup>
                <Button
                    margin={"0 40px 0 0"}
                    type="submit"
                    color="brand2"
                    fullwidth
                >
                    Iniciar sesión
                </Button>
                <StyledDivider>o</StyledDivider>
                <StyledBottomText>
                    ¿No tienes una cuenta?{" "}
                    <Anchor path="/app/auth/signup">Crear una cuenta</Anchor>
                </StyledBottomText>
            </form>
        </StyledWrap>
    );
};

export default SigninForm;
