import { useState, useEffect } from "react";
import Cookies from "js-cookie";

interface CookieOptions {
    expires?: number | Date;
    path?: string;
    domain?: string;
    secure?: boolean;
}

export const useCookie = (key: string, initialValue: string) => {
    const [storedValue, setStoredValue] = useState(initialValue);

    useEffect(() => {
        // Leer el valor de la cookie al montar el componente
        const cookieValue = Cookies.get(key) as string;
        if (cookieValue) {
            setStoredValue(cookieValue);
        }
    }, [key]);

    const setValue = (value: string, options?: CookieOptions) => {
        // Guardar el valor en la cookie y en el estado
        Cookies.set(key, value, options);
        setStoredValue(value);
    };

    return { storedValue, setValue };
};
