import { FocusEvent, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import {
    Label,
    FormGroup,
    Input,
    Button,
    Text,
    Container,
    Col,
    Row,
} from "@doar/components";
import { hasKey } from "@doar/shared/methods";
import { StyledWrap, StyledTitle, StyledDesc } from "./style";
import { Logo90px } from "../logo";
import { useCreateCompany } from "../../hooks/create-company/useCreateCompany";
import { login } from "../../redux/slices/auth";
import { useAppSelector } from "../../redux/hooks";
import Cookies from "js-cookie";
import { useFetchByZip } from "../../hooks/useFetchByZIP";
import { SelectPlatformProduct } from "../custom/auth/select-platform-product/SelectPlatformProduct";
import { useFetchBankList } from "../../hooks/bank-list/useFetchBankList";

interface IFormValues {
    accountNumber: string;
    address1: string;
    address2: string;
    zipCode: string;
    state: string;
    city: string;
    phone: string;
    email: string;
    bank: string;
    password: string;
    canonicalName: string;
    legalName: string;
    exteriorNumber: string;
    interiorNumber: string;
    nickname: string;
    shortName: string;
}

const CreateCompanyForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormValues>();

    const dispatch = useDispatch();
    const { loginInfo, selectedProductPlatform } = useAppSelector(
        (state) => state.auth
    );

    const {
        city,
        stateData,
        setCity,
        colonies,
        setStateData,
        fetchStatus,
        mutateAsync: fetchZipInfo,
    } = useFetchByZip("", false);

    const { createCompany, companyCreated, error, loading } =
        useCreateCompany();
    const [phoneNumber, setPhoneNumber] = useState();

    const { bankList } = useFetchBankList({});

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        void createCompany({
            account_number: selectedProductPlatform ? data.accountNumber : null,
            address1: data.address1,
            address2: data.address2,
            bank: data.bank,
            canonical_name: data.canonicalName,
            city: city || data.city,
            email: data.email,
            exterior_number: data.exteriorNumber,
            interior_number: data.interiorNumber,
            shortname: data.shortName,
            zip_code: data.zipCode,
            state: stateData || data.state,
            phone: phoneNumber || "",
            legal_name: data.legalName,
            token: loginInfo.token || "",
            group_id:
                selectedProductPlatform === "fuel"
                    ? "2"
                    : selectedProductPlatform === "fleet"
                    ? "3"
                    : "" || "",
        });
    };

    const handleBlurZipCode = async (
        e: FocusEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        if (e.target.value.length === 5) {
            await fetchZipInfo(e.target.value);
        }
    };

    useEffect(() => {
        if (fetchStatus === "failed") {
            toast.error(
                "Ocurrió un problema al obtener la información con el código postal"
            );
        }
    }, [fetchStatus, city, stateData, setCity, setStateData]);

    useEffect(() => {
        if (companyCreated?.id) {
            toast.success("Empresa creada correctamente");
            dispatch(
                login({
                    loginInfo: {
                        ...loginInfo,
                        client_id: companyCreated?.id,
                    },
                })
            );
            Cookies.set(
                "auth2",
                JSON.stringify({
                    status: "authenticated",
                    id: loginInfo.id,
                    email: loginInfo.email,
                    image_name: loginInfo.image_name,
                    image_url: loginInfo.image_url,
                    client_id: companyCreated?.id,
                    token: loginInfo.token,
                    fleetAdmin: loginInfo.fleetAdmin,
                    fuelAdmin: loginInfo.fuelAdmin,
                    superAdmin: loginInfo.superAdmin,
                    hash_employee: loginInfo.hash_employee,
                    validation_status: loginInfo.validation_status,
                })
            );
        }
    }, [companyCreated, dispatch, loginInfo]);

    useEffect(() => {
        if (error) {
            toast.error(
                "Ocurrió un problema al crear la empresa. Intente más tarde."
            );
        }
    }, [error]);

    return (
        <StyledWrap>
            <Container>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Logo90px />
                </div>
                <div style={{ padding: "10px" }}></div>
                <StyledTitle>Registra tu empresa</StyledTitle>
                <StyledDesc>Es gratis y solo te tomará un minuto.</StyledDesc>
                {!loading && (
                    <form
                        action="#"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        <Row>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="canonicalName"
                                    >
                                        Nombre comercial*
                                    </Label>
                                    <Input
                                        id="canonicalName"
                                        type="text"
                                        placeholder="Introduce el nombre comercial"
                                        feedbackText={
                                            errors?.canonicalName?.message
                                        }
                                        state={
                                            hasKey(errors, "canonicalName")
                                                ? "error"
                                                : "success"
                                        }
                                        showState={
                                            !!hasKey(errors, "canonicalName")
                                        }
                                        {...register("canonicalName", {
                                            required:
                                                "El nombre comercial es requerido",
                                        })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="address1"
                                    >
                                        Dirección*
                                    </Label>
                                    <Input
                                        id="address1"
                                        type="text"
                                        placeholder="Introduce la dirección"
                                        feedbackText={errors?.address1?.message}
                                        state={
                                            hasKey(errors, "address1")
                                                ? "error"
                                                : "success"
                                        }
                                        showState={!!hasKey(errors, "address1")}
                                        {...register("address1", {
                                            required:
                                                "La dirección es requerida",
                                        })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="exteriorNumber"
                                    >
                                        Número exterior*
                                    </Label>
                                    <Input
                                        id="exteriorNumber"
                                        type="text"
                                        placeholder="Introduce el número exterior"
                                        feedbackText={
                                            errors?.exteriorNumber?.message
                                        }
                                        state={
                                            hasKey(errors, "exteriorNumber")
                                                ? "error"
                                                : "success"
                                        }
                                        showState={
                                            !!hasKey(errors, "exteriorNumber")
                                        }
                                        {...register("exteriorNumber", {
                                            required:
                                                "El número exterior es requerido",
                                        })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="interiorNumber"
                                    >
                                        Número interior
                                    </Label>
                                    <Input
                                        id="interiorNumber"
                                        type="text"
                                        placeholder="Introduce el número interior"
                                        feedbackText={
                                            errors?.interiorNumber?.message
                                        }
                                        state={
                                            hasKey(errors, "interiorNumber")
                                                ? "error"
                                                : "success"
                                        }
                                        showState={
                                            !!hasKey(errors, "interiorNumber")
                                        }
                                        {...register("interiorNumber")}
                                    />
                                </FormGroup>
                            </Col>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="zipCode"
                                    >
                                        Código postal*
                                    </Label>
                                    <Input
                                        id="zipCode"
                                        type="text"
                                        placeholder="Introduce el código postal"
                                        feedbackText={errors?.zipCode?.message}
                                        state={
                                            hasKey(errors, "zipCode")
                                                ? "error"
                                                : "success"
                                        }
                                        showState={!!hasKey(errors, "zipCode")}
                                        {...register("zipCode", {
                                            required:
                                                "El código postal es requerido",
                                        })}
                                        onBlur={handleBlurZipCode}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="address2"
                                    >
                                        Colonia
                                    </Label>
                                    {fetchStatus === "success" ? (
                                        <select
                                            id="suburb"
                                            {...register("address2", {
                                                required: true,
                                            })}
                                            style={{
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            {colonies.map((colony) => (
                                                <option
                                                    key={colony}
                                                    value={colony}
                                                >
                                                    {colony}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <Input
                                            id="address2"
                                            type="text"
                                            placeholder="Introduce la colonia"
                                            feedbackText={
                                                errors?.address2?.message
                                            }
                                            state={
                                                hasKey(errors, "address2")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={
                                                !!hasKey(errors, "address2")
                                            }
                                            {...register("address2")}
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="state"
                                    >
                                        Estado*
                                    </Label>
                                    {fetchStatus === "success" ? (
                                        <Input
                                            id="state"
                                            name="state"
                                            type="text"
                                            value={stateData}
                                            disabled
                                        />
                                    ) : (
                                        <Input
                                            id="state"
                                            type="text"
                                            value={stateData}
                                            placeholder="Introduce el estado"
                                            feedbackText={
                                                errors?.state?.message
                                            }
                                            state={
                                                hasKey(errors, "state")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={
                                                !!hasKey(errors, "state")
                                            }
                                            {...register("state", {
                                                required:
                                                    "El estado es requerido",
                                            })}
                                            onChange={(e) =>
                                                setStateData(e.target.value)
                                            }
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="city"
                                    >
                                        Ciudad*
                                    </Label>
                                    {fetchStatus === "success" ? (
                                        <Input
                                            id="city"
                                            name="city"
                                            type="text"
                                            value={city}
                                            disabled
                                        />
                                    ) : (
                                        <Input
                                            id="city"
                                            type="text"
                                            value={city}
                                            placeholder="Introduce la ciudad"
                                            feedbackText={errors?.city?.message}
                                            state={
                                                hasKey(errors, "city")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={!!hasKey(errors, "city")}
                                            {...register("city", {
                                                required:
                                                    "La ciudad es requerida",
                                            })}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                            <Col col>
                                <FormGroup mb="20px">
                                    <Label
                                        display="block"
                                        mb="5px"
                                        htmlFor="phone"
                                    >
                                        Teléfono*
                                    </Label>
                                    <PhoneInput
                                        placeholder="Introduce el teléfono"
                                        defaultCountry="MX"
                                        value={phoneNumber}
                                        limitMaxLength
                                        onChange={(value) =>
                                            setPhoneNumber(
                                                value as SetStateAction<undefined>
                                            )
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* Only with fuel */}
                        {selectedProductPlatform === "fuel" && (
                            <Row>
                                <Col col>
                                    <FormGroup mb="20px">
                                        <Label
                                            display="block"
                                            mb="5px"
                                            htmlFor="accountNumber"
                                        >
                                            CLABE Interbancaria*
                                        </Label>
                                        <Input
                                            id="accountNumber"
                                            type="accountNumber"
                                            placeholder="Introduce la CLABE interbancaria"
                                            feedbackText={
                                                errors?.accountNumber?.message
                                            }
                                            state={
                                                hasKey(errors, "account_number")
                                                    ? "error"
                                                    : "success"
                                            }
                                            showState={
                                                !!hasKey(
                                                    errors,
                                                    "account_number"
                                                )
                                            }
                                            {...register("accountNumber", {
                                                required:
                                                    "La CLABE Interbancaria es requerida",
                                            })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col col>
                                    <FormGroup mb="20px">
                                        <Label
                                            display="block"
                                            mb="5px"
                                            htmlFor="accountNumber"
                                        >
                                            Selecciona el banco*
                                        </Label>
                                        <input
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            id="bank"
                                            type="bank"
                                            list="bankList"
                                            placeholder="Selecciona el banco"
                                            {...register("bank", {
                                                required:
                                                    "El banco es requerido",
                                            })}
                                        />
                                        <datalist
                                            id="bankList"
                                            title="Selecciona un banco"
                                        >
                                            {bankList.map((bank) => {
                                                return (
                                                    <option
                                                        key={bank.id}
                                                        value={bank.title}
                                                    >
                                                        {bank.title}
                                                    </option>
                                                );
                                            })}
                                        </datalist>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        <FormGroup mb="20px">
                            <Label display="block" mb="5px" htmlFor="email">
                                Correo electrónico*
                            </Label>
                            <input
                                style={{
                                    borderRadius: "5px",
                                }}
                                id="email"
                                type="email"
                                defaultValue={loginInfo.email || ""}
                                placeholder="Introduce el correo electrónico"
                                {...register("email", {
                                    required:
                                        "El correo electrónico es requerido",
                                })}
                            />
                        </FormGroup>
                        <FormGroup mb="20px">
                            <SelectPlatformProduct />
                        </FormGroup>
                        <FormGroup mb="20px">
                            <Text fontSize="12px">
                                Al hacer click en <strong>Crear empresa</strong>{" "}
                                garantizas que los datos proporcionados son
                                correctos y te haces responsable de los mismos.
                            </Text>
                        </FormGroup>
                        <Button
                            type="submit"
                            color="brand2"
                            fullwidth
                            disabled={selectedProductPlatform === ""}
                        >
                            Crear empresa
                        </Button>
                    </form>
                )}
            </Container>
        </StyledWrap>
    );
};

export default CreateCompanyForm;
