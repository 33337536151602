import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Preloader from "./components/preloader";
import { useCheckAuth } from "./hooks/check-authorization";
import DashboardRoutes from "./routes/dashboard-routes";

import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "@doar/components";
import "react-select-search/style.css";
import "./global.css";
import { HomePage } from "./pages/home";
import { AboutPage } from "./pages/about";
import { ProductsPage } from "./pages/products";
import { ContactPage } from "./pages/contact";
import Faqs from "./pages/faqs";
import "react-slideshow-image/dist/styles.css";
import "react-phone-number-input/style.css";
import { useSelectPlatformProduct } from "./hooks/select-platform-product/useSelectPlatformProduct";

const ErrorNotFound = lazy(() => import("./pages/error-404"));

const App = () => {
    const status = useCheckAuth();
    useSelectPlatformProduct();

    if (status === "checking") {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route path="/app/*" element={<DashboardRoutes />} />

                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/products" element={<ProductsPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/*" element={<ErrorNotFound />} />
                        <Route path="/help" element={<Faqs />} />
                    </Routes>
                </Suspense>
                <ToastContainer />
            </Router>
        </>
    );
};

export default App;
