import { Heart } from "react-feather";
import {
    StyledFooter,
    StyledFooterLeft,
    StyledFooterRight,
    StyledFooterNav,
    StyledFotterNavLink,
} from "./style";

const Footer = () => {
    return (
        <StyledFooter>
            <StyledFooterRight>
                <StyledFooterNav>
                    <StyledFotterNavLink
                        path={process.env.REACT_APP_PORTAL_DESPACHADOR_URL}
                    >
                        Portal despachador
                    </StyledFotterNavLink>
                    <StyledFotterNavLink
                        path={process.env.REACT_APP_PORTAL_ADMIN_URL}
                    >
                        Portal admin
                    </StyledFotterNavLink>
                </StyledFooterNav>
            </StyledFooterRight>
            <StyledFooterLeft>
                <span>&copy; CityGas {new Date().getFullYear()} </span>
                <span className="copright-link">
                    HECHO CON <Heart size="24" /> POR{"  "}
                    <a
                        href="https://www.ixaya.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        IXAYA
                    </a>
                </span>
            </StyledFooterLeft>
        </StyledFooter>
    );
};

export default Footer;
