import Cookies from "js-cookie";
import { loginWithEmailPassword } from "../../services";
import {
    checkingCredentials,
    clearSelectedProductPlatform,
    login,
    logout,
    setSelectedProductPlatform,
} from "../slices/auth";
import { AppDispatch } from "../store";
import { toast } from "react-toastify";

export const startLoginWithEmailPassword = ({
    email,
    password,
}: {
    email: string;
    password: string;
}) => {
    return async (dispatch: AppDispatch) => {
        dispatch(checkingCredentials());

        const result = await loginWithEmailPassword(email, password);

        if (!result.success) {
            toast.error(result.errorMessage as string);
            return dispatch(logout({ errorMessage: result.errorMessage }));
        }

        const cStatus = result.data.status;
        const cUserId = result.data.info?.id;
        const cEmail = result.data.info?.email;
        const cImageName = result.data.info?.image_name;
        const cImageURL = result.data.info?.image_url;
        const cClientId = result.data.info?.client_id;
        const cUserGroups = result.data.info?.user_groups;
        const cToken = result.data.api_key;
        const cHashEmployee = result.data.info.hash_employee;
        const cValidationStatus = result.data.info.validation_status || "2";

        const cFleetAdmin =
            result.data.info.user_groups.includes("fleet") ||
            result.data.info.user_groups.includes("admin");
        const cFuelAdmin =
            result.data.info.user_groups.includes("fuel") ||
            result.data.info.user_groups.includes("admin");
        const cSuperAdmin = result.data.info.user_groups.includes("admin");

        Cookies.set(
            "auth2",
            JSON.stringify({
                status: cStatus,
                id: cUserId,
                email: cEmail,
                image_name: cImageName,
                image_url: cImageURL,
                client_id: cClientId,
                user_groups: cUserGroups,
                token: cToken,
                hash_employee: cHashEmployee,
                fleetAdmin: cFleetAdmin,
                fuelAdmin: cFuelAdmin,
                superAdmin: cSuperAdmin,
                validation_status: cValidationStatus,
            })
        );
        dispatch(
            login({
                loginInfo: {
                    id: cUserId,
                    email: cEmail,
                    image_name: cImageName,
                    image_url: cImageURL,
                    client_id: cClientId,
                    user_groups: cUserGroups,
                    token: cToken,
                    fleetAdmin: cFleetAdmin,
                    fuelAdmin: cFuelAdmin,
                    superAdmin: cSuperAdmin,
                    hash_employee: cHashEmployee,
                    validation_status: cValidationStatus,
                },
            })
        );
    };
};

export const startSetProductPlatformSelection = (product: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(
            setSelectedProductPlatform({
                selectedProductPlatform: product,
            })
        );
        Cookies.set("selectedProduct", product);
    };
};

export const startLogOut = () => {
    return (dispatch: AppDispatch) => {
        Cookies.remove("auth2");
        Cookies.remove("selectedProduct");
        dispatch(clearSelectedProductPlatform());

        dispatch(logout({ errorMessage: "" }));
    };
};
