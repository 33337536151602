import { StyledLogo } from "./style";

const Logo = () => {
    return (
        <StyledLogo path="/">
            <img
                src="/city_gas_vector.svg"
                alt="CityGas logo"
                style={{ height: "50px", marginBottom: "10%" }}
            />
        </StyledLogo>
    );
};

export default Logo;

export const Logo90px = () => {
    return (
        <StyledLogo path="/">
            <img
                src="/city_gas_vector.svg"
                alt="CityGas logo"
                style={{ height: "125px" }}
            />
        </StyledLogo>
    );
};
