import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorNotFound from "../pages/error-404";
import PrivateRouteWrapper from "./private-route-wrapper";
import { useAppSelector } from "../redux/hooks";

const Profile = lazy(() => import("../pages/dashboard/profile"));
const Clients = lazy(() => import("../pages/dashboard/Clients"));

const Dashboard = lazy(
    () => import("../modules/admin/dashboard/pages/dashboard")
);
const ClientsList = lazy(
    () => import("../modules/admin/clients-all/pages/home/clients")
);
const ClientDetails = lazy(
    () =>
        import("../modules/admin/clients-all/pages/content/client-all-content")
);

const ClientsFleet = lazy(
    () => import("../modules/admin/clients-fleet/pages/home/client-fleet")
);

const ClientsFleetDetail = lazy(
    () =>
        import(
            "../modules/admin/clients-fleet/pages/content/client-fleet-content"
        )
);

const ClientsFuel = lazy(
    () => import("../modules/admin/clients-fuel/pages/home/client-fuel")
);
const ClientsFuelDetail = lazy(
    () =>
        import(
            "../modules/admin/clients-fuel/pages/content/client-fuel-content"
        )
);

const AdminTags = lazy(() => import("../modules/admin/tags/pages/index"));

const ClientsRequests = lazy(
    () =>
        import("../modules/admin/clients-requests/pages/home/clients-requests")
);

const ClientsRequestDetails = lazy(
    () =>
        import(
            "../modules/admin/clients-requests/pages/details/client-request-details"
        )
);

const AdminBills = lazy(
    () => import("../modules/admin/bills/pages/bills/bills-list-layout")
);

const AdminRoutes = () => {
    const { loginInfo } = useAppSelector((state) => state.auth);
    const EMPLOYEE_HASH = loginInfo.hash_employee || "";
    const navigateToLocation = EMPLOYEE_HASH
        ? `/app/admin/employees/${EMPLOYEE_HASH}`
        : `/app/choose`;

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/app/admin/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route
                path="/client-profile"
                element={
                    <PrivateRouteWrapper
                        allowedGroups={["admin"]}
                        navigateTo={navigateToLocation}
                    >
                        <Clients />
                    </PrivateRouteWrapper>
                }
            />
            <Route path="/bills" element={<AdminBills />} />
            <Route path="/clients-all" element={<ClientsList />} />
            <Route path="/clients-fleet" element={<ClientsFleet />} />
            <Route path="/clients-fleet/:id" element={<ClientsFleetDetail />} />
            <Route path="/clients-fuel" element={<ClientsFuel />} />
            <Route path="/clients-fuel/:id" element={<ClientsFuelDetail />} />
            <Route path="/clients-all/:id" element={<ClientDetails />} />
            <Route path="/tags" element={<AdminTags />} />
            <Route path="/clients-requests" element={<ClientsRequests />} />
            <Route
                path="/clients-requests/:id"
                element={<ClientsRequestDetails />}
            />

            <Route path="/*" element={<ErrorNotFound />} />
        </Routes>
    );
};

export default AdminRoutes;
