/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from "react";
import {
    // Search,
    Menu,
    X,
    ArrowLeft,
    Package,
    HelpCircle,
    Archive,
    Mail,
    Home,
} from "react-feather";
import { Navbar, Button } from "@doar/components";
import { menuData } from "@doar/shared/data";
// import MessageDropdown from "../../components/header/message-dropdown";
// import NotificationDropdown from "../../components/header/notification-dropdown";
import ProfileDropdown from "../../components/header/profile-dropdown";
import NavSearch from "../../components/header/nav-search";
import Logo from "../../components/logo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleSidebar, toggleBody } from "../../redux/slices/ui";
import {
    StyledHeader,
    StyledLogo,
    StyledNavbarWrap,
    StyledNavbarMenu,
    StyleNavbarRight,
    StyledNavbarElement,
    StyledNavbarHeader,
    StyledNavbarBody,
    StyledNavbarTitle,
    StyledMenuBtn,
    StyledSidebarBtn,
} from "./style";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
    hasSidebar?: boolean;
    sidebarLayout?: 1 | 2;
}

const Header = ({ hasSidebar, sidebarLayout }: IProps) => {
    const dispatch = useAppDispatch();
    const { sidebar, isBody } = useAppSelector((state) => state.ui);
    const { loginInfo } = useAppSelector((state) => state.auth);

    const location = useLocation();
    const navigate = useNavigate();

    const [searchOpen, setSearchOpen] = useState(false);
    const searchHandler = useCallback(() => {
        setSearchOpen((prev) => !prev);
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarHandler = useCallback(
        (_: any, isOpen?: "open") => {
            dispatch(toggleSidebar({ isOpen }));
        },
        [dispatch]
    );

    const bodyHandler = useCallback(() => {
        dispatch(toggleBody());
        dispatch(toggleSidebar({ isOpen: "open" }));
    }, [dispatch]);

    const menuHandler = useCallback(() => {
        setMenuOpen((prev) => !prev);
        if (menuOpen) {
            sidebarHandler(undefined, "open");
        }
    }, [menuOpen, sidebarHandler]);

    useEffect(() => {
        return () => {
            sidebarHandler(undefined, "open");
            bodyHandler();
        };
    }, [sidebarHandler, bodyHandler]);

    const getNavBarMenu = () => {
        if (location.pathname.includes("admin")) {
            return <Navbar menus={menuData.admin} />;
        } else if (location.pathname.includes("fleet")) {
            return (
                <Navbar
                    menus={
                        loginInfo.fleetAdmin
                            ? menuData.fleet
                            : menuData.fleet_employee
                    }
                />
            );
        } else if (location.pathname.includes("fuel")) {
            return (
                <Navbar
                    menus={
                        loginInfo.fuelAdmin
                            ? menuData.fuel
                            : menuData.fuel_employee
                    }
                />
            );
        }
        return null;
    };

    return (
        <>
            <StyledHeader>
                {hasSidebar && sidebarLayout === 1 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="menu-btn"
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={!isBody ? sidebarHandler : bodyHandler}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="sidebar-btn"
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {hasSidebar && sidebarLayout === 2 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={!sidebar}
                            $bodyOpen={false}
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={sidebarHandler}
                            $sidebarOpen={!sidebar}
                            $bodyOpen={false}
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {!hasSidebar && (
                    <StyledMenuBtn
                        variant="texted"
                        onClick={menuHandler}
                        $hasSidebar={hasSidebar}
                        $sidebarOpen={!sidebar}
                    >
                        <Menu size={20} strokeWidth="2.5px" />
                    </StyledMenuBtn>
                )}
                <StyledLogo>
                    <Logo />
                </StyledLogo>
                {loginInfo.token &&
                    location.pathname.includes("/app") &&
                    !location.pathname.includes("/app/auth/create-company") &&
                    !location.pathname.includes("/app/auth/affiliate") &&
                    !location.pathname.includes(
                        "/app/auth/verification-process"
                    ) && (
                        <>
                            <StyledNavbarWrap
                                $isOpen={menuOpen}
                                onClick={menuHandler}
                                className="navbar-wrap"
                            >
                                <StyledNavbarMenu
                                    $isOpen={menuOpen}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <StyledNavbarHeader>
                                        <Logo />
                                        <Button
                                            variant="texted"
                                            onClick={menuHandler}
                                        >
                                            <X
                                                color="#7987a1"
                                                width={20}
                                                strokeWidth="2.5px"
                                            />
                                        </Button>
                                    </StyledNavbarHeader>
                                    <StyledNavbarBody>
                                        <StyledNavbarTitle>
                                            NAVEGACIÓN PRINCIPAL
                                        </StyledNavbarTitle>
                                        {getNavBarMenu()}
                                    </StyledNavbarBody>
                                </StyledNavbarMenu>
                            </StyledNavbarWrap>
                            <StyleNavbarRight>
                                <StyledNavbarElement
                                    ml={["15px", "20px", "30px"]}
                                >
                                    <ProfileDropdown />
                                </StyledNavbarElement>
                            </StyleNavbarRight>
                        </>
                    )}
                {(!location.pathname.includes("/app") ||
                    location.pathname.includes("/app/auth")) && (
                    <>
                        <StyledNavbarWrap
                            $isOpen={menuOpen}
                            onClick={menuHandler}
                            className="navbar-wrap"
                        >
                            <StyledNavbarMenu
                                $isOpen={menuOpen}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <StyledNavbarHeader>
                                    <Logo />
                                    <Button
                                        variant="texted"
                                        onClick={menuHandler}
                                    >
                                        <X
                                            color="#7987a1"
                                            width={20}
                                            strokeWidth="2.5px"
                                        />
                                    </Button>
                                </StyledNavbarHeader>
                                <StyledNavbarBody>
                                    <StyledNavbarTitle>
                                        MENÚ INICIO
                                    </StyledNavbarTitle>
                                    <Navbar
                                        menus={[
                                            {
                                                id: 1,
                                                label: "Inicio",
                                                url: "/",
                                                Icon: Home,
                                            },
                                            {
                                                id: 2,
                                                label: "Productos",
                                                url: "/products",
                                                Icon: Package,
                                            },
                                            {
                                                id: 3,
                                                label: "Contacto",
                                                url: "/contact",
                                                Icon: Mail,
                                            },
                                            {
                                                id: 4,
                                                label: "Centro de Ayuda",
                                                url: "/help",
                                                Icon: HelpCircle,
                                            },
                                            {
                                                id: 5,
                                                label: "Acerca de",
                                                url: "/about",
                                                Icon: Archive,
                                            },
                                        ]}
                                    />
                                </StyledNavbarBody>
                            </StyledNavbarMenu>
                        </StyledNavbarWrap>
                        <StyleNavbarRight>
                            <StyledNavbarElement ml={["15px", "20px", "30px"]}>
                                {loginInfo.token ? (
                                    <ProfileDropdown />
                                ) : (
                                    !location.pathname.includes(
                                        "/app/auth"
                                    ) && (
                                        <Button
                                            onClick={() =>
                                                navigate("/app/auth/signin")
                                            }
                                        >
                                            Iniciar sesión
                                        </Button>
                                    )
                                )}
                            </StyledNavbarElement>
                        </StyleNavbarRight>
                    </>
                )}
            </StyledHeader>
            <NavSearch isOpen={searchOpen} onClose={searchHandler} />
        </>
    );
};

Header.defaultProps = {
    sidebarLayout: 1,
};

export default Header;
