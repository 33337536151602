import { Col, Nav, NavLink, Row } from "@doar/components";
import styled, { themeGet } from "@doar/shared/styled";
import Content from "../../layouts/content";
import Layout from "../../layouts";
import { useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import "./style.css";

import faqsJson from "../../data/faqs.json";
import { Faq } from "./Faq";

export const StyleLabel = styled.h6`
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    color: ${themeGet("colors.text3")};
    margin-bottom: 15px;
`;

export const StyleTextAlign = styled.p`
    text-align: justify;
`;

const FaqsCont = () => {
    const [current, setCurrent] = useState(0);

    return (
        <Layout>
            <div className="body">
                <Content>
                    <Row>
                        <Col lg={3} xl={2} mb={["20px", null, null, 0]}>
                            <StyleLabel>Ayuda</StyleLabel>
                            <Nav customStyle="classic">
                                <NavLink
                                    path="#faqs"
                                    onClick={() => setCurrent(0)}
                                    active={current === 0}
                                >
                                    FAQs
                                </NavLink>
                            </Nav>
                        </Col>
                        <Col lg={9} xl={10}>
                            <Breadcrumb
                                prev={[{ text: "Dashboard", link: "/" }]}
                                title={"FAQs"}
                            />
                            <div>
                                <h3 className="title">Preguntas Frecuentes</h3>
                                <Row>
                                    <Col sm={12}>
                                        <div className="questions-container">
                                            {faqsJson.faqs.map((faq, index) => (
                                                <Faq
                                                    key={index}
                                                    question={faq.question}
                                                    answer={faq.answer}
                                                />
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>
        </Layout>
    );
};

export default FaqsCont;
