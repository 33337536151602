import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "@doar/components";
import image from "@doar/shared/images/img18.png";
import { StyledWrap, StyledImg, StyledTitle, StyledDesc } from "./style";
import { cityGasAPI } from "../../apis";
import { CheckCircle } from "react-feather";

const PasswordContainer = () => {
    const [username, setUsername] = useState("");
    const [sent, setSent] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await cityGasAPI.post("/login/forgot_password", {
            username: username,
        });
        toast.success("Correo enviado correctamente");
        setSent(true);
    };

    return (
        <StyledWrap>
            <StyledImg>
                <img src={image} alt="forgot password" />
            </StyledImg>
            {!sent ? (
                <>
                    <StyledTitle>Recupera tu contraseña</StyledTitle>
                    <StyledDesc>
                        Ingresa tu correo electrónico y se te enviará un enlace
                        para que puedas restablecer tu contraseña.
                    </StyledDesc>
                    <form style={{ display: "flex" }} onSubmit={handleSubmit}>
                        <Input
                            onChange={(e) => setUsername(e.target.value)}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Correo electrónico"
                            width={[null, "250px"]}
                        />
                        <Button
                            type="submit"
                            color="brand2"
                            mt={["10px", 0]}
                            ml={[0, "10px"]}
                        >
                            Enviar
                        </Button>
                    </form>
                </>
            ) : (
                <div style={{ display: "flex" }}>
                    <StyledTitle>Revisa tu correo electrónico</StyledTitle>
                    <div style={{ margin: "0 0 0 20px" }}>
                        <CheckCircle color="green" />
                    </div>
                </div>
            )}
        </StyledWrap>
    );
};

export default PasswordContainer;
