import {
    Users,
    PieChart,
    Repeat,
    Folder,
    Truck,
    DollarSign,
    Tag,
    Navigation2,
    Box,
} from "react-feather";

const menus = {
    fleet: [
        {
            id: 1,
            label: "Dashboard",
            url: "/app/fleet/dashboard",
            Icon: PieChart,
        },
        {
            id: 2,
            label: "Empleados",
            url: "/app/fleet/employees",
            Icon: Users,
        },
        {
            id: 3,
            label: "Vehiculos",
            url: "/app/fleet/vehicles",
            Icon: Truck,
        },
        {
            id: 4,
            label: "Grupos",
            url: "/app/fleet/groups",
            Icon: Folder,
        },
        {
            id: 5,
            label: "Tags",
            url: "/app/fleet/tags",
            Icon: Tag,
        },
        {
            id: 6,
            label: "Transacciones",
            url: "/app/fleet/transactions",
            Icon: Repeat,
        },
        {
            id: 7,
            label: "Estado de cuenta",
            url: "/app/fleet/bills",
            Icon: DollarSign,
        },
    ],
    fleet_employee: [
        {
            id: 1,
            label: "Empleado",
            url: "/app/fleet/employees",
            Icon: Users,
        },
        {
            id: 2,
            label: "Tag",
            url: "/app/fleet/tags",
            Icon: Tag,
        },
    ],
    fuel: [
        {
            id: 1,
            label: "Dashboard",
            url: "/app/fuel/dashboard",
            Icon: PieChart,
        },
        {
            id: 2,
            label: "Empleados",
            url: "/app/fuel/employees",
            Icon: Users,
        },
        {
            id: 3,
            label: "Estaciones",
            url: "/app/fuel/stations",
            Icon: Navigation2,
        },
        {
            id: 4,
            label: "Productos",
            url: "/app/fuel/products",
            Icon: Folder,
        },
        {
            id: 5,
            label: "Transacciones",
            url: "/app/fuel/transactions",
            Icon: Repeat,
        },
        // {
        //     id: 6,
        //     label: "Facturas",
        //     url: "/app/fuel/invoices",
        //     Icon: DollarSign,
        // },
        {
            id: 6,
            label: "Estado de cuenta",
            url: "/app/fuel/bills",
            Icon: DollarSign,
        },
    ],
    fuel_employee: [
        {
            id: 1,
            label: "Empleado",
            url: "/app/fuel/employees",
            Icon: Users,
        },
    ],
    admin: [
        {
            id: 0,
            label: "Dashboard",
            url: "/app/admin/dashboard",
            Icon: PieChart,
        },
        {
            id: 1,
            label: "Clientes",
            url: "/app/admin/clients-all",
            Icon: Users,
        },
        {
            id: 2,
            label: "Flotillas",
            url: "/app/admin/clients-fleet",
            Icon: Truck,
        },
        {
            id: 3,
            label: "Gasolineras",
            url: "/app/admin/clients-fuel",
            Icon: Navigation2,
        },
        {
            id: 4,
            label: "Solicitudes",
            url: "/app/admin/clients-requests",
            Icon: Box,
        },
        {
            id: 5,
            label: "Tags",
            url: "/app/admin/tags",
            Icon: Tag,
        },
        {
            id: 6,
            label: "Estados de cuenta",
            url: "/app/admin/bills",
            Icon: DollarSign,
        },
    ],
};

export default menus;
