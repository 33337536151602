import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { cityGasAPI } from "../../apis/cityGasAPI";
import {
    IBank,
    IBankListResponse,
} from "../../interfaces/bank-list/bankListResponse";

export const useFetchBankList = ({ search }: { search?: string }) => {
    const [bankList, setBankList] = useState<IBank[]>([] as IBank[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    useEffect(() => {
        const controller = new AbortController();
        const fetchBankList = async () => {
            setLoading(true);
            try {
                const response: AxiosResponse<IBankListResponse> =
                    await cityGasAPI.get("/clients/banks", {
                        // headers: {
                        //     "X-API-KEY": token,
                        // },
                        params: {
                            search,
                        },
                    });
                setBankList(response.data.response);
            } catch (err) {
                setError(err);
                console.log(err);
            }
            setLoading(false);
        };
        void fetchBankList();
        return () => {
            controller.abort();
        };
    }, [
        search,
        // token
    ]);

    const mutateAsync = async () => {
        const fetchBankList = async () => {
            setLoading(true);
            try {
                const response: AxiosResponse<IBankListResponse> =
                    await cityGasAPI.post("/clients/banks", {
                        // headers: {
                        //     "X-API-KEY": token,
                        // },
                        params: {
                            search,
                        },
                    });
                setBankList(response.data.response);
            } catch (err) {
                setError(err);
                console.log(err);
            }
            setLoading(false);
        };
        await fetchBankList();
    };

    return { bankList, mutateAsync, loading, error };
};
