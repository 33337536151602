import {
    Label,
    FormGroup,
    Input,
    Anchor,
    Button,
    Text,
} from "@doar/components";
import { useForm, SubmitHandler } from "react-hook-form";
import { hasKey } from "@doar/shared/methods";
import { StyledWrap, StyledTitle, StyledDesc, StyledBottomText } from "./style";
import { Logo90px } from "../logo";
import { useCreateUser } from "../../hooks/auth/useCreateUser";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/slices/auth";
import { SelectPlatformProduct } from "../custom/auth/select-platform-product/SelectPlatformProduct";

interface IFormValues {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
}

const SigninForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormValues>();

    const { selectedProductPlatform } = useAppSelector((state) => state.auth);

    const { createUser, error, loading, userCreated } = useCreateUser();
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<IFormValues> = async (data) => {
        await createUser({
            username: data.email,
            email: data.email,
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone,
        });
    };

    useEffect(() => {
        if (userCreated.id) {
            toast.success("Usuario creado correctamente");
            dispatch(
                login({
                    loginInfo: {
                        client_id: userCreated.client_id,
                        email: userCreated.email,
                        id: userCreated.id,
                        image_name: userCreated.image_name,
                        image_url: userCreated.image_url,
                        token: userCreated.api_key,
                        user_groups: [],
                        fleetAdmin: false,
                        fuelAdmin: false,
                        superAdmin: false,
                        hash_employee: userCreated.hash_employee,
                        validation_status: userCreated.validation_status,
                    },
                })
            );
        }
    }, [dispatch, userCreated]);

    useEffect(() => {
        if (error) {
            toast.error(error as string);
        }
    }, [error]);

    return (
        <StyledWrap>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Logo90px />
            </div>
            <div style={{ padding: "10px" }}></div>
            <StyledTitle>Crear nueva cuenta</StyledTitle>
            <StyledDesc>Es gratis y solo te tomará un minuto.</StyledDesc>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="first_name">
                            Nombre(s)
                        </Label>
                        <Input
                            id="first_name"
                            type="text"
                            placeholder="Introduce tu nombre"
                            feedbackText={errors?.first_name?.message}
                            state={
                                hasKey(errors, "first_name")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "first_name")}
                            {...register("first_name", {
                                required: "El nombre es requerido",
                                minLength: {
                                    value: 2,
                                    message: "Minimum length is 2",
                                },
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="last_name">
                            Apellidos
                        </Label>
                        <Input
                            id="last_name"
                            type="text"
                            placeholder="Introduce tu apellido"
                            feedbackText={errors?.last_name?.message}
                            state={
                                hasKey(errors, "last_name")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "last_name")}
                            {...register("last_name", {
                                required: "El apellido es requerido",
                                minLength: {
                                    value: 2,
                                    message: "La longitud mínima es 2",
                                },
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="email">
                            Correo electrónico
                        </Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Introduce tu correo electrónico"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "El correo electrónico es requerido",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "correo electrónico invalido",
                                },
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="password">
                            Contraseña
                        </Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Introduce tu contraseña"
                            feedbackText={errors?.password?.message}
                            state={
                                hasKey(errors, "password") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "password")}
                            {...register("password", {
                                required: "La contraseña es requerida",
                                minLength: {
                                    value: 6,
                                    message: "La longitud mínima es 6",
                                },
                                maxLength: {
                                    value: 10,
                                    message: "La longitud máxima es 10",
                                },
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <SelectPlatformProduct />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Text fontSize="12px">
                            Al hacer click en <strong>Registrarse</strong>{" "}
                            debajo, estás de acuerdo con nuestros Términos y
                            Condiciones y Política de privacidad.
                        </Text>
                    </FormGroup>
                    <Button
                        type="submit"
                        color="brand2"
                        disabled={!selectedProductPlatform}
                        marginLeft={"34%"}
                    >
                        Registrarse
                    </Button>
                    <StyledBottomText>
                        ¿Ya tienes una cuenta?{" "}
                        <Anchor path="/app/auth/signin">Inicia sesión</Anchor>
                    </StyledBottomText>
                </form>
            )}
        </StyledWrap>
    );
};

export default SigninForm;
