import { cityGasAPI } from "../apis";

export async function loginWithEmailPassword(email: string, password: string) {
    try {
        const response = await cityGasAPI.post("login", {
            username: email,
            password: password,
        });

        if (response.data.status === -1) {
            return {
                success: false,
                errorMessage: response.data.message,
            };
        }

        return {
            success: true,
            data: response.data,
        };
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        console.log(error);
        return {
            success: false,
            errorMessage: error.message,
        };
    }
}
