import { FC, useState } from "react";
import styled, { themeGet } from "@doar/shared/styled";

interface Props {
    question: string;
    answer: string;
}

export const StyleLabel = styled.h6`
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    color: ${themeGet("colors.text3")};
    margin-bottom: 15px;
`;

export const StyleTextAlign = styled.p`
    text-align: justify;
`;

export const Faq: FC<Props> = ({ question, answer }) => {
    const [selectedFaq, setSelectedFaq] = useState(false);
    return (
        <div className="question">
            <button onClick={() => setSelectedFaq(!selectedFaq)}>
                <span>{question}</span>
                <i
                    className={
                        "fas fa-chevron-down d-arrow" +
                        (selectedFaq ? "rotate" : "")
                    }
                ></i>
            </button>
            <StyleTextAlign className={selectedFaq ? "show" : ""}>
                {answer}
            </StyleTextAlign>
        </div>
    );
};
