import { useEffect, useState } from "react";
import { Check } from "react-feather";
import { toast } from "react-toastify";
import {
    Label,
    FormGroup,
    Input,
    Anchor,
    Button,
    Text,
} from "@doar/components";
import { useForm, SubmitHandler } from "react-hook-form";
import { hasKey } from "@doar/shared/methods";
import { StyledWrap, StyledTitle, StyledDesc, StyledBottomText } from "./style";
import { Logo90px } from "../logo";
import { useRFCValidation } from "../../hooks/rfc-validation/useRFCValidation";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useUpdateCompanyDocuments } from "../../hooks/update-company-documents/useUpdateCompanyDocuments";
import { login } from "../../redux/slices/auth";
import Cookies from "js-cookie";

interface IFormValues {
    rfc: string;
    proofOfTaxDomain: FileList;
    constitutiveAct: FileList;
}

const AffiliateForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormValues>();

    const {
        updateCompanyDocuments,
        updatedCompanyDocuments,
        error: errorUpdateDocuments,
        loading: loadingUpdateDocuments,
    } = useUpdateCompanyDocuments();

    const [isRFCValid, setIsRFCValid] = useState<boolean>(false);
    const {
        error: errorRFC,
        loading: loadingRFC,
        rfcValidated,
        validateRFC,
    } = useRFCValidation();
    const [rfcInputValue, setRFCInputValue] = useState<string>("");
    const { loginInfo } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const handleRFCValidation = () => {
        if (rfcInputValue.length === 12 || rfcInputValue.length === 13) {
            void validateRFC({
                rfc: rfcInputValue,
                token: loginInfo.token || "",
            });
        } else {
            return toast.error("El RFC debe tener 12 o 13 caracteres");
        }
    };

    const onSubmit: SubmitHandler<IFormValues> = async (data) => {
        console.log(data.proofOfTaxDomain[0].type);
        console.log(data.constitutiveAct[0].type);
        if (
            (data.proofOfTaxDomain[0].type === "application/pdf" ||
                data.proofOfTaxDomain[0].type === "image/jpeg" ||
                data.proofOfTaxDomain[0].type === "image/png") &&
            (data.constitutiveAct[0].type === "application/pdf" ||
                data.constitutiveAct[0].type === "image/jpeg" ||
                data.constitutiveAct[0].type === "image/png")
        ) {
            if (isRFCValid) {
                await updateCompanyDocuments({
                    token: loginInfo.token || "",
                    rfc: rfcInputValue,
                    client_id: loginInfo.client_id || "",
                    proof_of_tax_domain: data.proofOfTaxDomain[0],
                    constitutive_act: data.constitutiveAct[0],
                });
            } else {
                toast.error("Primero debes ingresar un RFC válido");
            }
        } else {
            toast.error(
                "Los archivos deben ser de tipo PDF, JPG o PNG. Intenta de nuevo."
            );
        }
    };

    useEffect(() => {
        if (updatedCompanyDocuments?.id) {
            dispatch(
                login({
                    loginInfo: {
                        ...loginInfo,
                        validation_status: "0",
                    },
                })
            );

            Cookies.set(
                "auth2",
                JSON.stringify({
                    status: "authenticated",
                    id: loginInfo?.id,
                    email: loginInfo?.email,
                    image_name: loginInfo?.image_name,
                    image_url: loginInfo?.image_url,
                    client_id: loginInfo?.id,
                    token: loginInfo?.token,
                    fleetAdmin: loginInfo?.fleetAdmin,
                    fuelAdmin: loginInfo?.fuelAdmin,
                    superAdmin: loginInfo?.superAdmin,
                    hash_employee: loginInfo?.hash_employee,
                    validation_status: "0",
                })
            );
            toast.success("Documentos actualizados correctamente");
        }
    }, [dispatch, loginInfo, updatedCompanyDocuments]);

    useEffect(() => {
        if (errorUpdateDocuments) {
            toast.error("Ocurrió un error al actualizar los documentos");
        }
    }, [errorUpdateDocuments]);

    useEffect(() => {
        if (errorRFC) {
            setIsRFCValid(false);
            toast.error("Ocurrió un error al validar el RFC");
        }
    }, [errorRFC]);

    useEffect(() => {
        if (rfcValidated?.valid) {
            setIsRFCValid(true);
            toast.success("RFC validado correctamente");
        } else if (rfcValidated?.valid === false) {
            setIsRFCValid(false);
            toast.error("RFC inválido");
        }
    }, [rfcValidated]);

    return (
        <StyledWrap>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Logo90px />
            </div>
            <div style={{ padding: "10px" }}></div>
            <StyledTitle>Afíliate</StyledTitle>
            <StyledDesc>
                El último paso es afiliarte con nosotros subiendo tus
                documentos.
            </StyledDesc>
            {loadingUpdateDocuments ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Text color="primary" fontSize="14px">
                        Actualizando documentos...
                    </Text>
                </div>
            ) : (
                <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="rfc">
                            Comprobante de dominio fiscal
                        </Label>
                        <Input
                            id="proofOfTaxDomain"
                            type="file"
                            placeholder="Introduce tu Comp. de dominio fiscal"
                            feedbackText={errors?.proofOfTaxDomain?.message}
                            state={hasKey(errors, "rfc") ? "error" : "success"}
                            showState={!!hasKey(errors, "rfc")}
                            {...register("proofOfTaxDomain", {
                                required:
                                    "El Comp. de dominio fiscal es requerido",
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="satAccount">
                            Acta constitutiva
                        </Label>
                        <Input
                            id="constitutiveAct"
                            type="file"
                            placeholder="Introduce tu Acta constitutiva"
                            feedbackText={errors?.constitutiveAct?.message}
                            state={
                                hasKey(errors, "satAccount")
                                    ? "error"
                                    : "success"
                            }
                            showState={!!hasKey(errors, "satAccount")}
                            {...register("constitutiveAct", {
                                required: "La cuenta del SAT es requerida",
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Label display="block" mb="5px" htmlFor="rfc">
                            RFC
                        </Label>
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: 2 }}>
                                <Input
                                    id="rfc"
                                    type="text"
                                    placeholder="Introduce el RFC de la empresa"
                                    feedbackText={errors?.rfc?.message}
                                    state={
                                        hasKey(errors, "rfc")
                                            ? "error"
                                            : "success"
                                    }
                                    showState={!!hasKey(errors, "rfc")}
                                    {...register("rfc", {
                                        required: "El RFC es requerido",
                                    })}
                                    value={rfcInputValue}
                                    onChange={(e) => {
                                        if (isRFCValid) {
                                            setIsRFCValid(false);
                                        }
                                        setRFCInputValue(e.target.value);
                                    }}
                                />
                            </div>
                            {loadingRFC ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#FFC107",
                                        flex: 1,
                                    }}
                                >
                                    <Text fontSize="15px" color="white">
                                        Validando RFC...
                                    </Text>
                                </div>
                            ) : (
                                <>
                                    {isRFCValid ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#15C266",
                                                flex: 1,
                                            }}
                                        >
                                            <Check color="white" />
                                            <Text fontSize="15px" color="white">
                                                RFC válido
                                            </Text>
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                handleRFCValidation()
                                            }
                                        >
                                            Validar RFC
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Text fontSize="12px">
                            Al hacer click en <strong>Afiliarse</strong> debajo,
                            estás de acuerdo con nuestros Términos y Condiciones
                            y Política de privacidad.
                        </Text>
                    </FormGroup>
                    <Button
                        disabled={!isRFCValid}
                        type="submit"
                        color="brand2"
                        fullwidth
                    >
                        Afiliarse
                    </Button>
                    <StyledBottomText>
                        ¿Ya estás afiliado?{" "}
                        <Anchor path="/app/auth/signin">Inicia sesión</Anchor>
                    </StyledBottomText>
                </form>
            )}
        </StyledWrap>
    );
};

export default AffiliateForm;
