import { User, LogOut, Briefcase, Home } from "react-feather";
import {
    DropdownToggle,
    Dropdown,
    Avatar,
    AvatarInitial,
    Text,
} from "@doar/components";
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { startLogOut } from "../../../redux/thunks/auth";
import { RootState } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { TooltipOnHover } from "../../custom/hover-tooltip/hover-tooltip";

const ProfileDropdown = () => {
    const { loginInfo, timeZone } = useAppSelector(
        (state: RootState) => state.auth
    );
    const dispatch = useAppDispatch();
    const location = useLocation();
    const groups = [
        ...new Set(loginInfo.user_groups ? loginInfo.user_groups : []),
    ];
    const handleLogout = () => {
        dispatch(startLogOut());
    };

    const shouldDisplayProfiles =
        groups &&
        (groups.includes("fleet") ||
            groups.includes("fuel") ||
            groups.includes("admin")) &&
        (location.pathname.includes("fleet") ||
            location.pathname.includes("fuel") ||
            location.pathname.includes("admin") ||
            location.pathname === "/" ||
            location.pathname === "/about" ||
            location.pathname === "/products" ||
            location.pathname === "/contact" ||
            location.pathname === "/help" ||
            location.pathname === "/app/choose");

    const fuelOrFleetOrAdminLabel = groups.includes("admin")
        ? "admin"
        : groups.includes("fleet")
        ? "fleet"
        : "fuel";

    // console.log(location);

    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                {loginInfo.image_url == null ? (
                    <TooltipOnHover
                        tooltip={loginInfo?.email}
                        offsetLeft={-120}
                        offsetTop={25}
                    >
                        <StyledAvatar size="sm" shape="circle">
                            <AvatarInitial>
                                {loginInfo?.email?.slice(0, 2)}
                            </AvatarInitial>
                        </StyledAvatar>
                    </TooltipOnHover>
                ) : (
                    <TooltipOnHover
                        tooltip={loginInfo?.email}
                        offsetLeft={-120}
                        offsetTop={25}
                    >
                        <img
                            src={`${loginInfo.image_url}`}
                            style={{ borderRadius: "50%" }}
                            width={33}
                            height={33}
                            alt=""
                        />
                    </TooltipOnHover>
                )}
            </DropdownToggle>
            <StyledDropMenu>
                {loginInfo.image_url == null ? (
                    <Avatar size="lg" shape="circle">
                        <AvatarInitial>
                            {loginInfo?.email?.slice(0, 2)}
                        </AvatarInitial>
                    </Avatar>
                ) : (
                    <img
                        src={`${loginInfo.image_url}`}
                        style={{ borderRadius: "50%" }}
                        width={64}
                        height={64}
                        alt=""
                    />
                )}
                <StyledAuthorName>{loginInfo?.email}</StyledAuthorName>
                {(loginInfo.fleetAdmin || loginInfo.fuelAdmin) && (
                    <StyledAuthorRole>Administrador</StyledAuthorRole>
                )}
                <Text color="gray" fontSize="0.7rem">
                    Zona horaria: {timeZone}
                </Text>
                {groups.includes("admin") && (
                    <StyledDropItem path={`/app/admin`} mt="10px">
                        <Home size="24" />
                        Dashboard
                    </StyledDropItem>
                )}
                {shouldDisplayProfiles && (
                    <>
                        {!groups.includes("admin") && (
                            <StyledDropItem
                                path={`/app/${fuelOrFleetOrAdminLabel}`}
                                mt="10px"
                            >
                                <Home size="24" />
                                Dashboard
                            </StyledDropItem>
                        )}
                        <StyledDropItem
                            path={`/app/${fuelOrFleetOrAdminLabel}/profile`}
                            mt="10px"
                        >
                            <User size="24" />
                            Ver perfil
                        </StyledDropItem>
                        <StyledDropItem
                            path={`/app/${fuelOrFleetOrAdminLabel}/client-profile`}
                            mt="10px"
                        >
                            <Briefcase size="24" />
                            Perfil de Empresa
                        </StyledDropItem>
                    </>
                )}

                {groups && groups.length > 1 && (
                    <>
                        <StyledDivider />
                        <StyledDropItem path="/app/choose" mt="10px">
                            Cambiar de plataforma
                        </StyledDropItem>
                        <StyledDivider />
                    </>
                )}
                <StyledDropItem
                    path="/app/auth/signin"
                    mt="10px"
                    onClick={handleLogout}
                >
                    <LogOut size="24" />
                    Cerrar sesión
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
