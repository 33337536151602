import SEO from "../components/seo";
import Layout from "../layouts";
import { Slide } from "react-slideshow-image";
import { Button, Col, Row, Text } from "@doar/components";
import HelpBox from "../components/help-center/help-box";
import loginImage from "@doar/shared/images/img15.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const HomePage = () => {
    const navigate = useNavigate();

    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 480px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(max-width: 480px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);

    const gradientStyle = {
        background: "linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent)",
    };

    const spanStyle = {
        display: "block",
        maxWidth: "600px",
        fontWeight: "500",
        fontSize: "1.1rem",
        padding: "0 20px",
        marginLeft: "50px",
        color: "#000000",
        opacity: "1",
    };

    // const divStyle = {
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "left",
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     height: "400px",
    // };
    // divStyle with blur
    const divStyle = {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        backgroundSize: "cover",
        height: "500px",
        backgroundPosition: "top",
    };

    const divStyle2 = {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        background: "right",
        height: "350px",
    };

    const slideImages = [
        {
            url: "https://i.postimg.cc/tJRMXySc/Group-1.png",
            caption:
                "Nuestro sistema de gestión de combustible utiliza la tecnología NFC y autenticación de doble factor para garantizar la seguridad de tus transacciones.",
        },
        {
            url: "https://i.postimg.cc/Vv4wWmH9/Group-3.png",
            caption:
                "Con Citygas, la gestión de combustible para tu flotilla de vehículos nunca ha sido más fácil y seguro.",
        },
    ];

    return (
        <Layout>
            <SEO />
            <Slide>
                {slideImages.map((slideImage, index) => (
                    <div key={index}>
                        <div
                            style={
                                matches
                                    ? {
                                          ...divStyle2,
                                          backgroundImage: `url(${slideImage.url})`,
                                      }
                                    : {
                                          ...divStyle,
                                          backgroundImage: `url(${slideImage.url})`,
                                      }
                            }
                        >
                            <div
                                style={{
                                    display: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <span
                                    style={{
                                        ...spanStyle,
                                        color: "rgba(63, 46, 203, 1)",
                                        textTransform: "uppercase",
                                        fontSize: "1rem",
                                    }}
                                >
                                    Soluciones de carga de combustible para
                                    empresas
                                </span>
                                <span style={spanStyle}>
                                    {slideImage.caption}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </Slide>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "60px 0 20px 0",
                }}
            >
                <Text padding={"0 1rem"} fontSize="1.5rem">
                    Sin importar el giro de tu empresa tenemos la solución para
                    ti
                </Text>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                    marginLeft: "24%",
                    marginBottom: "5%",
                }}
            >
                <div style={{ marginRight: "4%" }}>
                    <HelpBox
                        icon="fa-truck"
                        title="Flotillas"
                        path="/products"
                        desc="Administración de flotillas para tu empresa"
                        topics="Saber más"
                    />
                </div>
                <div style={{}}>
                    <HelpBox
                        icon="fa-gas-pump"
                        path="/products"
                        title="Gasolineras"
                        desc="Administración de gasolineras para tu empresa"
                        topics="Saber más"
                    />
                </div>
                <div style={{ marginLeft: "4%" }}>
                    <HelpBox
                        icon="fa-handshake"
                        title="Partners"
                        path="/products"
                        desc="Otros servicios de CityGas"
                        topics="Saber más"
                    />
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "#17559A",
                    minHeight: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    color: "#fff",
                    fontSize: "1.5rem",
                    padding: "2rem 1rem",
                }}
            >
                <span
                    style={{
                        color: "white",
                        fontSize: "2.5rem",
                        padding: "0.25rem",
                        textAlign: "center",
                    }}
                >
                    ¡Solicita tu afiliación hoy mismo!
                </span>
                <span
                    style={{ fontSize: "1rem", padding: "1rem 1rem 2rem 1rem" }}
                >
                    No pierdas más tiempo en largas colas en las gasolineras y
                    disfruta de la eficiencia y seguridad de Citygas
                </span>
                <Button
                    color="white"
                    size="lg"
                    fontWeight={600}
                    width="200px"
                    onClick={() => navigate("/app/auth/signup")}
                >
                    Afíliate
                </Button>
            </div>
            <div
                style={{
                    maxWidth: "1000px",
                    margin: "20px auto",
                    padding: "2rem 0",
                }}
            >
                <Row>
                    <Col sm={6} mt={["20px", 0]}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{ maxWidth: "470px" }}
                                src={loginImage}
                                alt="Login"
                            />
                        </div>
                    </Col>
                    <Col sm={6} mt={["20px", "30px", null, 0]}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                margin: "20px 0 20px 20px",
                            }}
                        >
                            <Text fontSize="1.5rem">
                                Requisitos para afiliarse
                            </Text>
                            {/* <div style={{ margin: "20px" }}>
                                <i className="fa fa-check" />
                                &nbsp; Contar con terminal bancaria o agregador
                                de medios de pago
                            </div> */}
                            <div style={{ margin: "20px" }}>
                                <i className="fa fa-check" />
                                &nbsp; Identificación de comercio RFC
                            </div>
                            <div style={{ margin: "20px" }}>
                                <i className="fa fa-check" />
                                &nbsp; Comprobante de domicilio fiscal
                            </div>
                            <div style={{ margin: "20px" }}>
                                <i className="fa fa-check" />
                                &nbsp; Acta constitutiva
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0",
                    marginTop: "30px",
                }}
            >
                <Text padding={"0 1rem"} fontSize="1.5rem">
                    Descubre la forma más segura y eficiente de administrar tus
                    vehículos con nuestra empresa de crédito.
                </Text>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "1000px",
                    margin: "20px auto",
                }}
            >
                <Row>
                    <Col sm={6} lg={4} mt={["20px", 0]}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                alt=""
                                src="https://www.edenred.mx/hubfs/site_edenred_mx/wp/Edenred-BE-Pago-Banco.png.webp"
                            />

                            <Text padding={"0 1rem"} fontSize="0.95rem">
                                Nuestra experiencia en la administración de
                                flotillas te garantiza un servicio eficiente y
                                personalizado para satisfacer tus necesidades.
                            </Text>
                        </div>
                    </Col>
                    <Col sm={6} lg={4} mt={["20px", "30px", null, 0]}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                alt=""
                                src="https://www.edenred.mx/hubfs/site_edenred_mx/wp/Edenred-BE-Pago-Banco.png.webp"
                            />
                            <Text padding={"0 1rem"} fontSize=".95rem">
                                Con nuestra innovadora tecnología NFC, podrás
                                autenticarte de forma rápida y sencilla sin
                                preocuparte por procedimientos tediosos.
                            </Text>
                        </div>
                    </Col>
                    <Col sm={6} lg={4}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                alt=""
                                src="https://www.edenred.mx/hubfs/site_edenred_mx/wp/Edenred-BE-Factura.png.webp"
                            />
                            <Text padding={"0 1rem"} fontSize=".95rem">
                                Ya no tendrás que preocuparte por los riesgos de
                                seguridad o las largas esperas en procesos de
                                autenticación.
                            </Text>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};
