import {
    Button,
    FormGroup,
    Input,
    Label,
    Text,
    Textarea,
} from "@doar/components";
import { toast } from "react-toastify";
import { StyledWrap } from "../../src/components/signup-form/style";
import { Logo90px } from "../../src/components/logo";

import { cityGasAPI } from "../apis";
import SEO from "../components/seo";
import Layout from "../layouts";
import Content from "../layouts/content";

export const ContactPage = () => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData);
        await cityGasAPI.post("/contacts/send", {
            email: data.email,
            subject: data.subject,
            message: data.message,
        });
        toast.success("Mensaje enviado correctamente");
        form.reset();
    };

    return (
        <Layout>
            <SEO />
            <StyledWrap>
                <Content fullHeight align="center">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <Logo90px />
                    </div>
                    <Text fontWeight="450" fontSize="1.5rem" textAlign="center">
                        Ponte en contacto con nosotros
                    </Text>
                    <form
                        onSubmit={handleSubmit}
                        style={{ maxWidth: "600px", margin: "auto" }}
                    >
                        <FormGroup mb="20px">
                            <Label display="block" mb="5px" htmlFor="email">
                                Correo electrónico
                            </Label>
                            <Input
                                name="email"
                                type="email"
                                id="email"
                                placeholder="Escribe tu correo"
                                mb="10px"
                            />
                            <Label display="block" mb="5px" htmlFor="subject">
                                Asunto
                            </Label>
                            <Input
                                name="subject"
                                type="email"
                                id="subject"
                                placeholder="Escribe el asunto del mensaje"
                                mb="10px"
                            />
                            <Label display="block" mb="5px" htmlFor="message">
                                Mensaje
                            </Label>
                            <Textarea
                                id="message"
                                name="message"
                                placeholder="Escribe el contenido del mensaje"
                                mb="30px"
                            />
                        </FormGroup>
                        <Button type="submit" color="brand2" fullwidth>
                            Enviar
                        </Button>
                    </form>
                </Content>
            </StyledWrap>
        </Layout>
    );
};
