import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
export const useFetchByZip = (zipCode: string, fetchOnMount = true) => {
    const [city, setCity] = useState("");
    const [stateData, setStateData] = useState("");
    const [colony, setColony] = useState("");
    const [colonies, setColonies] = useState<string[]>([]);
    const [fetchStatus, setFetchStatus] = useState<string>("reset");

    useEffect(() => {
        if (fetchOnMount) {
            const fetchColonies = async () => {
                const bodyFormData = new FormData();
                bodyFormData.append("zip", zipCode);

                const response: AxiosResponse<{
                    city: string;
                    state: string;
                    colony: string;
                    colonies: string[];
                    zip: string;
                }> = await axios({
                    method: "post",
                    url: "https://api.dev.app.citygas.mx/admin/SepomexService/all_by_cp_json",
                    data: bodyFormData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.colonies.length > 0) {
                    setCity(response.data.city);
                    setStateData(response.data.state);
                    setColony(response.data.colony);
                    setColonies(response.data.colonies);
                    setFetchStatus("success");
                } else {
                    setFetchStatus("failed");
                }
            };

            if (zipCode.length == 5 && fetchStatus == "reset") {
                fetchColonies().catch((Error) => console.log(Error));
            } else if (zipCode.length < 5) {
                setColonies([]);
                setCity("");
                setColony("");
                setStateData("");
                setFetchStatus("reset");
            }
        }
    }, [zipCode, fetchStatus, fetchOnMount]);

    const mutateAsync = async (newZipCode: string) => {
        const bodyFormData = new FormData();
        bodyFormData.append("zip", newZipCode);

        const response: AxiosResponse<{
            city: string;
            state: string;
            colony: string;
            colonies: string[];
            zip: string;
        }> = await axios({
            method: "post",
            url: "https://api.dev.app.citygas.mx/admin/SepomexService/all_by_cp_json",
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.data.colonies.length > 0) {
            setCity(response.data.city);
            setStateData(response.data.state);
            setColony(response.data.colony);
            setColonies(response.data.colonies);
            setFetchStatus("success");
        } else {
            setFetchStatus("failed");
        }
    };

    return {
        city,
        colonies,
        colony,
        stateData,
        fetchStatus,
        setCity,
        setStateData,
        setColony,
        mutateAsync,
    };
};
